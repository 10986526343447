import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectChartInfoByClientId } from '../../../../../../features/user';
import ChartEngagement from '../../../../../components/ChartEngagement';
import { getOptionChart } from '../../../../../utils/chartHelper';
import { defaultChartValue } from '../../../../../utils/constants';
import { get } from 'lodash';

function EngagementTab() {
  const chartInfo = useSelector(selectChartInfoByClientId);

  const [assignedChart, setAssignedChart] = useState(defaultChartValue);
  const [completedChart, setCompletedChart] = useState(defaultChartValue);

  //activities assigned
  useEffect(() => {
    const total = get(chartInfo, 'assigned_activities.total', 0);
    const today = get(chartInfo, 'assigned_activities.today', 0);
    const yesterday = get(chartInfo, 'assigned_activities.yesterday', 0);
    const week = get(chartInfo, 'assigned_activities.week', 0);
    const month = get(chartInfo, 'assigned_activities.month', 0);
    const datasets = [
      {
        name: 'Today',
        completed: today,
      },
      {
        name: 'Yesterday',
        completed: yesterday,
      },
      {
        name: 'last 7 days',
        completed: week,
      },
      {
        name: 'last 30 days',
        completed: month,
      },
    ];
    const series = datasets.map((d) =>
      Math.round((d.completed * 100) / 100, 0)
    );
    const options = getOptionChart(datasets);
    setAssignedChart({ total, today, yesterday, week, month, series, options });
  }, [chartInfo]);

  //completed activities
  useEffect(() => {
    const total = get(chartInfo, 'completed_activities.total', 0);
    const today = get(chartInfo, 'completed_activities.today', 0);
    const yesterday = get(chartInfo, 'completed_activities.yesterday', 0);
    const week = get(chartInfo, 'completed_activities.week', 0);
    const month = get(chartInfo, 'completed_activities.month', 0);

    const datasets = [
      {
        name: 'Today',
        completed: today,
      },
      {
        name: 'Yesterday',
        completed: yesterday,
      },
      {
        name: 'last 7 days',
        completed: week,
      },
      {
        name: 'last 30 days',
        completed: month,
      },
    ];
    const series = datasets.map((d) =>
      Math.round((d.completed * 100) / 100, 0)
    );
    const options = getOptionChart(datasets);
    setCompletedChart({
      total,
      today,
      yesterday,
      week,
      month,
      series,
      options,
    });
  }, [chartInfo]);

  return (
    <div className="row">
      <ChartEngagement
        chartInfo={assignedChart}
        title="Number of activities assigned"
        total="Total released"
        prefix="Released"
      />
      <ChartEngagement
        chartInfo={completedChart}
        title="Number of activities completed"
        total="Total completed"
        prefix="Completed"
      />
    </div>
  );
}

export default EngagementTab;
