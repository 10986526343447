import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { fetchFAQ } from '../../../../features/client/faq';
import { get } from 'lodash';

const Faq = () => {
  const dispatch = useDispatch();
  const [categaryList, setCategaryList] = useState([]);
  const [categarySelected, setCategarySelected] = useState();

  useEffect(() => {
    const fetchCategory = async function () {
      const queryParams = {
        _sort: 'created_at:asc',
      };
      const result = await dispatch(fetchFAQ(queryParams)).unwrap();
      if (result) {
        setCategaryList(result);
        setCategarySelected(result[0]);
      }
    };
    fetchCategory();
  }, []);
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container px-40">
          <div className="d-flex flex-column flex-md-row">
            <div className="flex-column flex-md-row-auto w-100 w-md-250px w-xxl-350px">
              <div className="card mb-10 mb-md-0">
                <div className="card-body py-10 px-6">
                  <ul className="menu menu-column menu-rounded menu-gray-600 menu-hover-bg-light-primary menu-active-bg-light-primary fw-bold mb-10">
                    <li className="menu-content fw-bold pb-2 px-3">
                      <span className="fs-3 fw-bolder">Category</span>
                    </li>
                    {categaryList.map((item, index) => (
                      <li
                        className="menu-item px-3 pb-1"
                        key={index}
                        onClick={() => setCategarySelected(item)}
                      >
                        <a
                          className={`menu-link fs-6 px-3 ${
                            item.id === get(categarySelected, 'id')
                              ? 'active'
                              : ''
                          }`}
                        >
                          {item.category}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex-md-row-fluid ms-md-12">
              <div className="card">
                <div className="card-body py-10">
                  <h2 className="text-dark fw-bolder fs-1 mb-5">Popular FAQ</h2>
                  <div
                    className="accordion accordion-icon-toggle"
                    id="kt_accordion_1"
                  >
                    {get(categarySelected, 'faqs', []).map((item, index) => (
                      <div className="mb-5" key={index}>
                        <div
                          className={`accordion-header py-3 d-flex ${
                            index === 0 ? '' : 'collapsed'
                          }`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#kt_accordion_1_item_${index}`}
                        >
                          <span className="accordion-icon">
                            <span className="svg-icon svg-icon-4  ">
                              <SVG src="/images/icons/right.svg" />
                            </span>
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            {item.question}
                          </h3>
                        </div>
                        <div
                          className={`fs-6 collapse ps-10 ${
                            index === 0 ? 'show' : ''
                          }`}
                          id={`kt_accordion_1_item_${index}`}
                          data-bs-parent="#kt_accordion_1"
                        >
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
