import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTopic,
  fetchTopics,
  selectTopicList,
  updateTopic,
} from '../../../../../features/admin/topic';
import { get } from 'lodash';
import { formatDate } from '../../../../utils/helper';
import { debounce } from 'lodash';
import { DebounceTimeDefault, UserType } from '../../../../utils/constants';
import { Link } from 'react-router-dom';
import TopicModal from './TopicModal';
import ConfirmModal from './ConfirmModal';
import { selectAuthUser } from '../../../../../features/user/auth';

const Topics = ({ refresh }) => {
  const dispatch = useDispatch();
  const topics = useSelector(selectTopicList);
  const authUser = useSelector(selectAuthUser);

  const [keyword, setKeyword] = useState('');
  const [selectedTopic, setSelectedTopic] = useState();

  // Modal
  const [isOpenTopicModal, setOpenTopicModal] = useState(false);
  const [isOpenConfimModal, setOpenConfimModal] = useState(false);

  useEffect(() => {
    dispatch(fetchTopics({ name_containss: keyword }));
  }, [refresh]);

  const handleOnSearch = (inputValue) => {
    dispatch(fetchTopics({ name_containss: inputValue }));
  };

  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  const handleSelectTopic = (e, selectedTopic) => {
    e.preventDefault();
    if (get(authUser, 'role.id') === UserType.SupperAdmin) {
      setSelectedTopic(selectedTopic);
      setOpenTopicModal(true);
    }
  };

  const onUpdateTopic = async (id, name) => {
    const data = {
      id,
      body: { name },
    };
    const result = await dispatch(updateTopic(data)).unwrap();
    if (result.data) {
      setOpenTopicModal(false);
      dispatch(fetchTopics({ name_containss: keyword }));
    }
  };

  const onDeleteTopic = () => {
    setOpenTopicModal(false);
    setOpenConfimModal(true);
  };

  const handleDeleteTopic = async () => {
    const result = await dispatch(deleteTopic(selectedTopic.id));
    if (result.payload.data) {
      setOpenConfimModal(false);
      dispatch(fetchTopics({ name_containss: keyword }));
    }
  };

  return (
    <>
      <div className="d-flex align-items-center position-relative mb-10">
        <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
          <img src="/images/icons/search.svg" alt="search-icon" />
        </span>
        <input
          className="form-control form-control-solid bg-white w-100 mw-350px ps-15 ms-n12"
          type="text"
          data-kt-docs-table-filter="search"
          placeholder="Search"
          value={keyword}
          onChange={(e) => handleOnChange(e)}
        />
      </div>
      {topics.map((topic) => (
        <div className="mb-5" key={topic.id}>
          <div className="d-flex shadow-sm bg-white rounded py-8 px-5 mb-5">
            <div className="me-2 topic-content-wrapper">
              <h3 className="fw-bolder mb-2">
                <a
                  className="text-gray-800 text-hover-primary"
                  href="#modal-topic"
                  onClick={(e) => handleSelectTopic(e, topic)}
                >
                  {get(topic, 'name')}
                </a>
              </h3>
              <div className="fw-bold fs-6 my-1 d-flex overflow-auto mx-n2">
                {get(topic, 'activities', []).map((activity, index) => (
                  <Link
                    key={index}
                    className="mx-2"
                    to={`/admin/edit-activity/${activity.id}`}
                  >
                    {get(activity, 'code')}
                  </Link>
                ))}
              </div>
            </div>
            <span className="text-muted fs-7 ms-auto">
              {formatDate(topic.created_at)}
            </span>
          </div>
        </div>
      ))}
      <TopicModal
        selectedTopic={selectedTopic}
        isOpenTopicModal={isOpenTopicModal}
        onCloseModal={() => setOpenTopicModal(false)}
        onSaveTopic={(id, name) => onUpdateTopic(id, name)}
        onDeleteTopic={onDeleteTopic}
      />

      <ConfirmModal
        isOpenConfimModal={isOpenConfimModal}
        onCloseModal={() => setOpenConfimModal(false)}
        handleDeleteTopic={handleDeleteTopic}
      />
    </>
  );
};

export default Topics;
