import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { get, find } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SVG from 'react-inlinesvg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ModalConfirm from '../../../../components/Modal/ModalConfirm';
import UploadImage from '../../../../components/UploadImage';
import { MultiSelect, SingleSelect } from '../../../../components/Selects';

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;

import {
  createActivity,
  fetchActivityById,
  fetchQuestionById,
  fetchChapterById,
  deleteChapter,
  deleteQuestion,
  deleteAnswer,
  deleteActivity,
} from '../../../../../features/admin/activity';
import { upload } from '../../../../../features/upload';

import './styles.scss';
import {
  fetchTopics,
  selectTopicList,
} from '../../../../../features/admin/topic';

const activities = {
  title: '',
  code: '',
  topics: [],
  summary: '',
  description: '',
  done_message: '',
  chapters: [
    {
      id: `chapter${uuidv4()}`,
      asset_type: 'Video',
      asset_url: '',
      chapter_notes: '',
      questions: [
        {
          id: `question${uuidv4()}`,
          question: '',
          answer_type: 'multiple_choice',
          answers: [
            {
              id: `answer${uuidv4()}`,
              description: '',
              is_correct: true,
            },
          ],
        },
      ],
    },
  ],
};

const selectOptions = [
  {
    title: 'Video',
    key: 'Video',
  },
  {
    title: 'Image',
    key: 'Image',
  },
  {
    title: 'Text',
    key: 'Text',
  },
];

const answerOptions = [
  {
    title: 'Mutiple Choice',
    key: 'multiple_choice',
  },
  {
    title: 'Text',
    key: 'text',
  },
];

const chapterTemplate = {
  id: `chapter${uuidv4()}`,
  asset_type: 'Text',
  asset_url: '',
  chapter_notes: '',
  questions: [
    {
      id: `question${uuidv4()}`,
      question: '',
      answer_type: 'multiple_choice',
      answers: [
        {
          id: `answer${uuidv4()}`,
          description: 'Yes',
          is_correct: true,
        },
        {
          id: `answer${uuidv4()}`,
          description: 'No',
          is_correct: false,
        },
      ],
    },
  ],
};

const questionTemplate = {
  id: `question${uuidv4()}`,
  question: '',
  answer_type: 'multiple_choice',
  answers: [
    {
      id: `answer${uuidv4()}`,
      description: '',
      is_correct: true,
    },
  ],
};

const answerTemplate = {
  id: `answer${uuidv4()}`,
  description: '',
  is_correct: false,
};

const DeleteType = {
  activity: 0,
  chapter: 1,
  question: 2,
  answer: 3,
};

const ActivityForm = () => {
  const dispatch = useDispatch();
  const { activityId } = useParams();
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [fileActivity, setFileActivity] = useState(405);
  const [activityPicture, setActivityPicture] = useState('');
  const [code, setCode] = useState('');
  // list tags
  const [tags, setTags] = useState([]);
  // tags of activity
  const [topics, setTopics] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const [isOpenQuestion, setIsOpenQuestion] = useState([]);
  const [hasDelete, setHasDelete] = useState('');
  const [chapters, setChapters] = useState(activities.chapters);
  const [summary, setSummary] = useState('');
  const [activityDescription, setActivityDescription] = useState('');
  const [doneMessage, setDoneMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // type delete
  const [deleteType, setDeleteType] = useState();

  const topicList = useSelector(selectTopicList);

  // disabled input
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(fetchTopics());
    if (activityId) {
      setDisabled(true);
    }
  }, []);

  const handleSubmitCreateActivity = async () => {
    if (isDisabled) {
      setDisabled(false);
    } else {
      const body = activityId
        ? {
            id: activityId,
            title,
            code,
            activity_picture: fileActivity,
            topics: topics.map((tag) => {
              return { id: tag.id };
            }),
            summary,
            description: activityDescription,
            done_message: doneMessage,
            chapters: chapters.map((chapter) => {
              if (chapter.id.toString().includes('chapter')) {
                return {
                  asset_type: chapter.asset_type,
                  asset_url: chapter.asset_url,
                  chapter_notes: chapter.chapter_notes,
                  questions: chapter.questions.map((ques) => {
                    if (ques.id.toString().includes('question')) {
                      return {
                        question: ques.question,
                        answer_type: ques.answer_type,
                        answers: ques.answers.map((ans) => {
                          if (ans.id.toString().includes('answer')) {
                            return {
                              description: ans.description,
                              is_correct: ans.is_correct,
                            };
                          }
                          return {
                            id: ans.id,
                            description: ans.description,
                            is_correct: ans.is_correct,
                          };
                        }),
                      };
                    }
                    return {
                      id: ques.id,
                      question: ques.question,
                      answer_type: ques.answer_type,
                      answers: ques.answers.map((ans) => {
                        if (ans.id.toString().includes('answer')) {
                          return {
                            description: ans.description,
                            is_correct: ans.is_correct,
                          };
                        }
                        return {
                          id: ans.id,
                          description: ans.description,
                          is_correct: ans.is_correct,
                        };
                      }),
                    };
                  }),
                };
              }
              return {
                id: chapter.id,
                asset_type: chapter.asset_type,
                asset_url: chapter.asset_url,
                chapter_notes: chapter.chapter_notes,
                questions: chapter.questions.map((ques) => {
                  if (ques.id.toString().includes('question')) {
                    return {
                      question: ques.question,
                      answer_type: ques.answer_type,
                      answers: ques.answers.map((ans) => {
                        if (ans.id.toString().includes('answer')) {
                          return {
                            description: ans.description,
                            is_correct: ans.is_correct,
                          };
                        }
                        return {
                          id: ans.id,
                          description: ans.description,
                          is_correct: ans.is_correct,
                        };
                      }),
                    };
                  }
                  return {
                    id: ques.id,
                    question: ques.question,
                    answer_type: ques.answer_type,
                    answers: ques.answers.map((ans) => {
                      if (ans.id.toString().includes('answer')) {
                        return {
                          description: ans.description,
                          is_correct: ans.is_correct,
                        };
                      }
                      return {
                        id: ans.id,
                        description: ans.description,
                        is_correct: ans.is_correct,
                      };
                    }),
                  };
                }),
              };
            }),
          }
        : {
            title,
            code,
            activity_picture: fileActivity,
            topics: tags.map((tag) => {
              return { id: tag.id };
            }),
            summary,
            description: activityDescription,
            done_message: doneMessage,
            chapters: chapters.map((chapter) => {
              return {
                asset_type: chapter.asset_type,
                asset_url: chapter.asset_url,
                chapter_notes: chapter.chapter_notes,
                questions: chapter.questions.map((ques) => {
                  return {
                    question: ques.question,
                    answer_type: ques.answer_type,
                    answers: ques.answers.map((ans) => {
                      return {
                        description: ans.description,
                        is_correct: ans.is_correct,
                      };
                    }),
                  };
                }),
              };
            }),
          };
      try {
        await dispatch(createActivity(body)).unwrap();
        return setIsSuccess(true);
      } catch (error) {
        return setIsError(true);
      }
    }
  };

  const deleteConfirm = (id, type) => () => {
    setHasDelete(id);
    setDeleteType(type);
  };

  const addChapter = (chapterId) => () => {
    const { id, ...chapterRest } = chapterTemplate;
    return setChapters([...chapters, { id: chapterId, ...chapterRest }]);
  };

  const setOpenChapter = (id) => () => {
    if (isOpen.includes(id)) {
      return setIsOpen([...isOpen.filter((ele) => ele !== id)]);
    }
    return setIsOpen([...isOpen, id]);
  };

  const confirmDelete = async () => {
    // delete activity
    if (deleteType === DeleteType.activity) {
      dispatch(deleteActivity(hasDelete));
    }
    // delete chapter
    else if (deleteType === DeleteType.chapter) {
      if (activityId) {
        const result = await dispatch(deleteChapter(hasDelete)).unwrap();
        if (result.data) {
          setChapters([...chapters.filter((chap) => chap.id !== hasDelete)]);
        }
      } else {
        setChapters([...chapters.filter((chap) => chap.id !== hasDelete)]);
      }
      // delete question
    } else if (deleteType === DeleteType.question) {
      if (activityId) {
        const result = await dispatch(deleteQuestion(hasDelete)).unwrap();
        if (result.data) {
          const chaptersAdjust = chapters.map((ele) => ({
            ...ele,
            questions: [
              ...ele.questions.filter((ques) => ques.id !== hasDelete),
            ],
          }));
          setChapters([...chaptersAdjust]);
        }
      } else {
        const chaptersAdjust = chapters.map((ele) => ({
          ...ele,
          questions: [...ele.questions.filter((ques) => ques.id !== hasDelete)],
        }));
        setChapters([...chaptersAdjust]);
      }
    }
    return setHasDelete('');
  };

  const addQuestion = (chapterId, questionId, answerId) => () => {
    const { id, answers, ...questionRest } = questionTemplate;
    const answersAdjust = [
      {
        id: answerId,
        description: '',
        is_correct: true,
      },
    ];
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          questions: [
            ...ele.questions,
            { id: questionId, answers: answersAdjust, ...questionRest },
          ],
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const openQuestion = (questionId) => () => {
    if (isOpenQuestion.includes(questionId)) {
      return setIsOpenQuestion([
        ...isOpenQuestion.filter((ele) => ele !== questionId),
      ]);
    }
    return setIsOpenQuestion([...isOpenQuestion, questionId]);
  };

  const addAnswer = (chapterId, questionId, answerId) => () => {
    const { id, ...ansRest } = answerTemplate;
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          questions: ele.questions.map((qus) => {
            if (qus.id === questionId) {
              return {
                ...qus,
                answers: [...qus.answers, { id: answerId, ...ansRest }],
              };
            }
            return {
              ...qus,
            };
          }),
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const setInputTags = (tags) => {
    setTopics(tags);
    setTags(tags);
  };

  const handleSetChapterContent = (value, chapterId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          asset_url: value,
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleSetChapterNotes = (value, chapterId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          chapter_notes: value,
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleSetAssetType = (value, chapterId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          asset_type: value.key,
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleSetQuestion = (value, chapterId, questionId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          questions: ele.questions.map((q) => {
            if (q.id === questionId) {
              return {
                ...q,
                question: value,
              };
            }
            return {
              ...q,
            };
          }),
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleSetAnswersType = (value, chapterId, questionId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          questions: ele.questions.map((q) => {
            if (q.id === questionId) {
              return {
                ...q,
                answer_type: value.key,
              };
            }
            return {
              ...q,
            };
          }),
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleSetAnswer = (value, chapterId, questionId, answerId) => {
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          questions: ele.questions.map((qus) => {
            if (qus.id === questionId) {
              return {
                ...qus,
                answers: qus.answers.map((ans) => {
                  if (ans.id === answerId) {
                    return {
                      ...ans,
                      description: value,
                    };
                  }
                  return {
                    ...ans,
                  };
                }),
              };
            }
            return {
              ...qus,
            };
          }),
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const toggleAnswer = (chapterId, questionId, answerId) => () => {
    if (!isDisabled) {
      const chaptersAdjust = chapters.map((ele) => {
        if (ele.id === chapterId) {
          return {
            ...ele,
            questions: ele.questions.map((qus) => {
              if (qus.id === questionId) {
                return {
                  ...qus,
                  answers: qus.answers.map((ans) => {
                    if (ans.id === answerId) {
                      return {
                        ...ans,
                        is_correct: !ans.is_correct,
                      };
                    }
                    return {
                      ...ans,
                      is_correct: false,
                    };
                  }),
                };
              }
              return {
                ...qus,
              };
            }),
          };
        }
        return {
          ...ele,
        };
      });
      return setChapters(chaptersAdjust);
    }
  };

  const handleDeleteAns = (chapterId, questionId, answerId) => async () => {
    if (activityId) {
      const result = await dispatch(deleteAnswer(answerId)).unwrap();
      if (result.data) {
        const chaptersAdjust = chapters.map((ele) => {
          if (ele.id === chapterId) {
            return {
              ...ele,
              questions: ele.questions.map((qus) => {
                if (qus.id === questionId) {
                  return {
                    ...qus,
                    answers: qus.answers.filter((ans) => ans.id !== answerId),
                  };
                }
                return {
                  ...qus,
                };
              }),
            };
          }
          return {
            ...ele,
          };
        });
        setChapters(chaptersAdjust);
      }
    } else {
      const chaptersAdjust = chapters.map((ele) => {
        if (ele.id === chapterId) {
          return {
            ...ele,
            questions: ele.questions.map((qus) => {
              if (qus.id === questionId) {
                return {
                  ...qus,
                  answers: qus.answers.filter((ans) => ans.id !== answerId),
                };
              }
              return {
                ...qus,
              };
            }),
          };
        }
        return {
          ...ele,
        };
      });
      setChapters(chaptersAdjust);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append(
      'fileInfo',
      JSON.stringify({ alternativeText: '', caption: '', name: null })
    );
    try {
      const uploadResponse = await dispatch(upload(formData)).unwrap();
      return uploadResponse;
    } catch (error) {
      return setIsError(true);
    }
  };

  const getImageActivity = async (file) => {
    const imageResource = await uploadImage(file);
    return setFileActivity(
      !!imageResource?.length && get(imageResource, '0.id', '')
    );
  };

  const getImageChapter = async (file, chapterId) => {
    const imageResource = await uploadImage(file);
    const chaptersAdjust = chapters.map((ele) => {
      if (ele.id === chapterId) {
        return {
          ...ele,
          asset_url: !!imageResource?.length && get(imageResource, '0.url', ''),
        };
      }
      return {
        ...ele,
      };
    });
    return setChapters(chaptersAdjust);
  };

  const handleConfirmSuccess = () => {
    history.push('/admin/activity-library');
    return setIsSuccess(false);
  };

  useEffect(() => {
    if (activityId) {
      const fetchActivity = async (activityId) => {
        const activityResult = await dispatch(
          fetchActivityById(activityId)
        ).unwrap();
        const {
          code,
          description,
          done_message,
          summary,
          title,
          activity_picture,
          chapters,
          topics,
        } = activityResult;
        setTitle(title);
        setSummary(summary);
        setDoneMessage(done_message);
        setActivityDescription(description);
        setCode(code);
        setTopics(
          topics.map((item) => ({
            id: item.id,
            value: item.name,
          }))
        );
        setFileActivity(activity_picture?.id || 11);
        setActivityPicture(activity_picture?.url || '');
        const chapterIdsPromise = chapters.map(
          async (chapter) =>
            await dispatch(fetchChapterById(chapter.id)).unwrap()
        );
        const listChapter = await Promise.all(chapterIdsPromise);
        const questionNestedChapter = listChapter.map(async (chap) => {
          const questions = await Promise.all(
            chap.questions.map(
              async (ques) =>
                await dispatch(fetchQuestionById(ques.id)).unwrap()
            )
          );
          return {
            ...chap,
            questions: questions,
          };
        });
        const chapterFull = await Promise.all(questionNestedChapter);
        setChapters(chapterFull);
      };
      fetchActivity(activityId);
    }
    return () => {
      setChapters([]);
    };
  }, [activityId]);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            uploadImage(file)
              .then((res) => {
                resolve({
                  default: get(res, '0.url', ''),
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="mb-10">
                  <div className="d-flex justify-content-between mb-5">
                    <label className="fw-bold fs-3 text-dark">
                      Select Activity Picture
                    </label>
                  </div>
                  <UploadImage
                    getImage={getImageActivity}
                    typeResource="Image"
                    defaultImage={activityPicture}
                    isDisabled={isDisabled}
                  />
                </div>
                <div className="row">
                  <div className="col mb-10">
                    <div className="d-flex justify-content-between mb-5">
                      <label className="fw-bold fs-3 text-dark">Title</label>
                      <span className="fw-bold fs-6 text-muted">{`${title.length}/100 characters`}</span>
                    </div>
                    <input
                      className="form-control"
                      defaultValue={title}
                      onChange={(e) => setTitle(e.target.value)}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-auto mb-10">
                    <div className="d-flex justify-content-between mb-5">
                      <label className="fw-bold fs-3 text-dark">Code</label>
                    </div>
                    <input
                      className="form-control"
                      defaultValue={code}
                      onChange={(e) => setCode(e.target.value)}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col mb-10">
                    <div className="d-flex justify-content-between mb-5">
                      <label className="fw-bold fs-3 text-dark">
                        Topic Tags
                      </label>
                    </div>
                    <MultiSelect
                      data={topicList.map((topic) => ({
                        id: topic.id,
                        value: topic.name,
                      }))}
                      placeholder={'Select topic'}
                      getInputTags={setInputTags}
                      defaultValue={topics}
                      isDisabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="mb-10">
                  <div className="d-flex justify-content-between mb-5">
                    <label className="fw-bold fs-3 text-dark">Summary</label>
                    <span className="fw-bold fs-6 text-muted">{`${summary.length}/800 characters`}</span>
                  </div>
                  <textarea
                    className="form-control"
                    value={summary || ''}
                    onChange={(e) => setSummary(e.target.value)}
                    disabled={isDisabled}
                  >
                    {summary}
                  </textarea>
                </div>
                <div className="mb-10">
                  <div className="d-flex justify-content-between mb-5">
                    <label className="fw-bold fs-3 text-dark">
                      Activity Description
                    </label>
                    <span className="fw-bold fs-6 text-muted">{`${activityDescription.length}/3,000 characters`}</span>
                  </div>
                  <CKEditor
                    disabled={isDisabled}
                    editor={ClassicEditor}
                    data={
                      activityDescription.startsWith('<')
                        ? activityDescription
                        : `<p>${activityDescription}</p>`
                    }
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setActivityDescription(data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                  />
                </div>
                <div className="mb-10">
                  <div>
                    {chapters &&
                      chapters.length > 0 &&
                      chapters.map((ele, index) => (
                        <div
                          className="mb-5"
                          key={ele.id}
                          style={{ cursor: 'pointer' }}
                        >
                          <div
                            className="py-3 d-flex"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_1_item_1"
                          >
                            <div
                              className="d-flex w-75"
                              onClick={setOpenChapter(ele.id)}
                            >
                              <span
                                className={`collapse-icon ${
                                  isOpen.includes(ele.id) ? 'open-icon' : ''
                                }`}
                              >
                                <span className="svg-icon svg-icon-4">
                                  <SVG src="/images/icons/down.svg" />
                                </span>
                              </span>
                              <h3 className="fw-bolder text-dark mb-0 ms-5 fs-1">{`Chapter ${
                                index < 9 ? `0${index + 1}` : `${index + 1}`
                              }`}</h3>
                            </div>
                            {!isDisabled && (
                              <span
                                className="ms-auto text-danger"
                                onClick={deleteConfirm(
                                  ele.id,
                                  DeleteType.chapter
                                )}
                              >
                                Delete Chapter
                              </span>
                            )}
                          </div>
                          <div
                            className={`fs-6 ps-10 ${
                              isOpen.includes(ele.id) ? 'appear' : 'disappear'
                            }`}
                          >
                            <div className="mb-10">
                              <div className="d-flex justify-content-between mb-5">
                                <label className="fw-bold fs-4 text-muted">
                                  Chapter Content
                                </label>
                              </div>
                              <div className="row">
                                <div className="col-lg-3">
                                  <SingleSelect
                                    data={selectOptions}
                                    defaultValue={find(selectOptions, {
                                      key: ele.asset_type,
                                    })}
                                    getData={(value) =>
                                      handleSetAssetType(value, ele.id)
                                    }
                                    isDisabled={isDisabled}
                                  />
                                </div>
                                <div className="col-lg-9">
                                  {ele.asset_type === 'Image' ? (
                                    <UploadImage
                                      getImage={(file) =>
                                        getImageChapter(file, ele.id)
                                      }
                                      typeResource={ele.asset_type}
                                      isDisabled={isDisabled}
                                    />
                                  ) : ele.asset_type === 'Text' ? (
                                    <CKEditor
                                      disabled={isDisabled}
                                      editor={ClassicEditor}
                                      data={
                                        ele.asset_url.startsWith('<')
                                          ? ele.asset_url
                                          : `<p>${ele.asset_url}</p>`
                                      }
                                      onChange={(event, editor) => {
                                        const data = editor.getData();
                                        handleSetChapterContent(data, ele.id);
                                      }}
                                      config={{
                                        extraPlugins: [uploadPlugin],
                                      }}
                                    />
                                  ) : (
                                    <input
                                      className="form-control fw-bold fs-6 text-gray-600"
                                      placeholder="chapter content here"
                                      defaultValue={ele.asset_url}
                                      onChange={(e) =>
                                        handleSetChapterContent(
                                          e.target.value,
                                          ele.id
                                        )
                                      }
                                      disabled={isDisabled}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-10 card border">
                              <div className="card-body">
                                <div className="accordion accordion-icon-toggle">
                                  {ele.questions.length > 0 &&
                                    ele.questions.map(
                                      (questionValue, indexQuestion) => (
                                        <div
                                          className="mb-5"
                                          key={questionValue.id}
                                        >
                                          <div
                                            className="accordion-header py-3 d-flex"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#kt_accordion_2_item_1"
                                            onClick={openQuestion(
                                              questionValue.id,
                                              ele.id
                                            )}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            <span
                                              className={`collapse-icon ${
                                                isOpenQuestion.includes(
                                                  questionValue.id
                                                )
                                                  ? 'open-icon'
                                                  : ''
                                              }`}
                                            >
                                              <span className="svg-icon svg-icon-4">
                                                <SVG src="/images/icons/down.svg" />
                                              </span>
                                            </span>
                                            <h3 className="fw-bold fs-3 text-dark fs-1 mb-0 ms-5">{`Question ${
                                              indexQuestion < 9
                                                ? `0${indexQuestion + 1}`
                                                : `${indexQuestion + 1}`
                                            }`}</h3>
                                            {!isDisabled && (
                                              <span
                                                className="ms-auto text-danger"
                                                onClick={deleteConfirm(
                                                  questionValue.id,
                                                  DeleteType.question
                                                )}
                                              >
                                                Delete Question
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            data-bs-parent="#kt_accordion_2"
                                            className={`fs-6 ps-10 ps-10 ${
                                              isOpenQuestion.includes(
                                                questionValue.id
                                              )
                                                ? 'appear'
                                                : 'disappear'
                                            }`}
                                          >
                                            <div className="mb-10">
                                              <div className="d-flex justify-content-between mb-2">
                                                <label className="fw-bold fs-5 text-dark">
                                                  Question
                                                </label>
                                                <span className="fw-bold fs-8 text-muted">{`${
                                                  questionValue?.question
                                                    ?.length ?? 0
                                                }/800 characters`}</span>
                                              </div>
                                              <textarea
                                                className="form-control"
                                                value={
                                                  questionValue?.question || ''
                                                }
                                                onChange={(e) =>
                                                  handleSetQuestion(
                                                    e.target.value,
                                                    ele.id,
                                                    questionValue.id
                                                  )
                                                }
                                                disabled={isDisabled}
                                              >
                                                {questionValue.question}
                                              </textarea>
                                            </div>
                                            <div className="mb-10">
                                              <div className="d-flex justify-content-between mb-2">
                                                <label className="fw-bold fs-5 text-dark">
                                                  Answer
                                                </label>
                                              </div>
                                              <div className="card border border-gray-300">
                                                <div className="card-body">
                                                  <div className="mb-10">
                                                    <div className="row">
                                                      <div className="col-auto">
                                                        <div className="d-flex justify-content-between mb-2">
                                                          <label className="fw-bold fs-6">
                                                            Type
                                                          </label>
                                                        </div>
                                                        <SingleSelect
                                                          data={answerOptions}
                                                          defaultValue={find(
                                                            answerOptions,
                                                            {
                                                              key: questionValue.answer_type,
                                                            }
                                                          )}
                                                          getData={(value) =>
                                                            handleSetAnswersType(
                                                              value,
                                                              ele.id,
                                                              questionValue.id
                                                            )
                                                          }
                                                          isDisabled={
                                                            isDisabled
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <div className="d-flex justify-content-between mb-2">
                                                          <label className="fw-bold fs-6">
                                                            Value
                                                          </label>
                                                          {questionValue.answer_type ===
                                                            'multiple_choice' && (
                                                            <span className="fw-bold fs-8 text-muted me-15">
                                                              Correct
                                                            </span>
                                                          )}
                                                        </div>
                                                        {questionValue.answer_type ===
                                                          'text' && (
                                                          <textarea
                                                            className="form-control me-5"
                                                            style={{
                                                              minHeight: 50,
                                                            }}
                                                            type="text"
                                                            defaultValue=""
                                                            disabled
                                                          />
                                                        )}
                                                        {questionValue.answer_type ===
                                                          'multiple_choice' &&
                                                          questionValue.answers
                                                            .length > 0 &&
                                                          questionValue.answers.map(
                                                            (
                                                              answ,
                                                              indexAnswer
                                                            ) => (
                                                              <div
                                                                key={answ.id}
                                                              >
                                                                <div className="form-check form-check-custom mb-2 mt-2">
                                                                  <input
                                                                    className="form-control me-5"
                                                                    type="text"
                                                                    defaultValue={
                                                                      answ.description
                                                                    }
                                                                    disabled={
                                                                      isDisabled
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleSetAnswer(
                                                                        e.target
                                                                          .value,
                                                                        ele.id,
                                                                        questionValue.id,
                                                                        answ.id
                                                                      )
                                                                    }
                                                                  />
                                                                  {
                                                                    <>
                                                                      {questionValue.answer_type ===
                                                                        'multiple_choice' && (
                                                                        <>
                                                                          <input
                                                                            className="form-check-input h-30px w-30px"
                                                                            type="checkbox"
                                                                            name="answer_01_01"
                                                                            value={
                                                                              answ.description
                                                                            }
                                                                            checked={
                                                                              answ.is_correct
                                                                            }
                                                                            onChange={toggleAnswer(
                                                                              ele.id,
                                                                              questionValue.id,
                                                                              answ.id
                                                                            )}
                                                                          />
                                                                          {!isDisabled && (
                                                                            <button
                                                                              className="btn btn-link ms-8"
                                                                              data-bs-toggle="modal"
                                                                              onClick={handleDeleteAns(
                                                                                ele.id,
                                                                                questionValue.id,
                                                                                answ.id
                                                                              )}
                                                                            >
                                                                              <span className="svg-icon svg-icon-2 svg-icon-danger">
                                                                                <SVG src="/images/icons/delete.svg" />
                                                                              </span>
                                                                            </button>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  }
                                                                </div>
                                                              </div>
                                                            )
                                                          )}

                                                        {questionValue.answer_type ===
                                                          'multiple_choice' &&
                                                          !isDisabled && (
                                                            <div
                                                              className="mb-5"
                                                              onClick={addAnswer(
                                                                ele.id,
                                                                questionValue.id,
                                                                `answer${uuidv4()}`
                                                              )}
                                                            >
                                                              <div className="accordion-header py-3 d-flex">
                                                                <span className="add-session-icon">
                                                                  <span className="svg-icon svg-icon-4  opacity-50">
                                                                    <SVG src="/images/icons/plus.svg" />
                                                                  </span>
                                                                </span>
                                                                <h3 className="fw-bold text-dark fs-5 mb-0 ms-5">
                                                                  <p className="add-session-txt">
                                                                    Add new
                                                                    answer
                                                                  </p>
                                                                </h3>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  {!isDisabled && (
                                    <div
                                      className="mb-5"
                                      onClick={addQuestion(
                                        ele.id,
                                        `question${uuidv4()}`,
                                        `answer${uuidv4()}`
                                      )}
                                    >
                                      <div className="accordion-header py-3 d-flex">
                                        <span className="add-session-icon">
                                          <span className="svg-icon svg-icon-4  opacity-50">
                                            <SVG src="/images/icons/plus.svg" />
                                          </span>
                                        </span>
                                        <h3 className="fw-bold text-dark fs-5 mb-0 ms-5">
                                          <p className="add-session-txt">
                                            Add Question
                                          </p>
                                        </h3>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-10">
                              <div className="d-flex justify-content-between mb-5">
                                <label className="fw-bold fs-4 text-muted">
                                  Correct Chapter Answers Notes{' '}
                                  <small>
                                    (What user sees after answering correct
                                    answer)
                                  </small>
                                </label>
                                <span className="fw-bold fs-6 text-muted">{`${get(
                                  ele,
                                  'chapter_notes.length',
                                  0
                                )}/100 characters`}</span>
                              </div>
                              <input
                                className="form-control"
                                defaultValue={ele.chapter_notes}
                                onChange={(e) =>
                                  handleSetChapterNotes(e.target.value, ele.id)
                                }
                                disabled={isDisabled}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    {!isDisabled && (
                      <div
                        className="mb-5"
                        style={{ cursor: 'pointer' }}
                        onClick={addChapter(`chapter${uuidv4()}`)}
                      >
                        <div className="py-3 d-flex">
                          <span className="add-session-icon">
                            <span className="svg-icon svg-icon-4">
                              <SVG src="/images/icons/plus.svg" />
                            </span>
                          </span>
                          <h3 className="fw-bolder mb-0 ms-5 fs-3">
                            <p className="add-session-txt">Add Chapter</p>
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-10">
                  <div className="d-flex justify-content-between mb-5">
                    <label className="fw-bold fs-3 text-dark">
                      DONE (WooHOO) MESSAGE
                    </label>
                    <span className="fw-bold fs-6 text-muted">{`${doneMessage.length}/3,000 characters`}</span>
                  </div>
                  <textarea
                    className="form-control fw-bold fs-6 text-gray-600"
                    value={doneMessage}
                    onChange={(e) => setDoneMessage(e.target.value)}
                    disabled={isDisabled}
                  >
                    {doneMessage}
                  </textarea>
                </div>

                <div className="mb-10">
                  {!isDisabled && activityId && (
                    <a
                      className="btn px-11 py-4 fw-bolder btn-link text-danger"
                      onClick={deleteConfirm(activityId, DeleteType.activity)}
                    >
                      Delete Activity
                    </a>
                  )}
                  <button
                    className={`btn px-11 py-4 fw-bolder float-end ${
                      !isDisabled ? 'btn-primary' : 'btn-light'
                    }`}
                    onClick={handleSubmitCreateActivity}
                  >
                    {`${!isDisabled ? 'Save' : 'Edit'}`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        isOpen={!!hasDelete}
        handleClose={() => setHasDelete('')}
        confirm={confirmDelete}
        modalTitle={'Are you sure you want to do this?'}
        modalNotes={'This action can not be undone'}
      />
      {isError && (
        <ModalConfirm
          isOpen={!!isError}
          handleClose={() => setIsError(false)}
          confirm={() => setIsError(false)}
          modalTitle={
            activityId ? 'Update Activity Failed' : 'Create Activity Failed'
          }
          modalNotes={'This action can not be undone!'}
        />
      )}
      {isSuccess && (
        <ModalConfirm
          isOpen={!!isSuccess}
          handleClose={() => setIsSuccess(false)}
          confirm={handleConfirmSuccess}
          modalTitle={
            activityId
              ? 'Update Activity Successfully'
              : 'Create Activity Successfully'
          }
          modalNotes={'This action was success!'}
          CustomIcon={CheckCircleIcon}
        />
      )}
    </div>
  );
};

export default ActivityForm;
