import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { get } from 'lodash';

function TopicModal({
  isCreateMode = false,
  isOpenTopicModal,
  onCloseModal,
  selectedTopic,
  onSaveTopic,
  onDeleteTopic,
}) {
  const [name, setName] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setName(get(selectedTopic, 'name'));
  }, [selectedTopic]);

  useEffect(() => {
    if (!isOpenTopicModal) {
      setName('');
    } else {
      setName(get(selectedTopic, 'name'));
    }
    setIsError(false);
  }, [isOpenTopicModal]);

  return (
    <Modal open={isOpenTopicModal} onClose={onCloseModal}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Topic</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setIsError(e.target.value.length === 0);
                    }}
                  />
                  {isError && (
                    <span className="invalid-feedback">
                      Topic name is required
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!isCreateMode && (
              <button
                className="btn btn-link me-auto text-danger"
                data-bs-toggle="modal"
                data-bs-target="#modal-warning"
                onClick={onDeleteTopic}
              >
                Remove
              </button>
            )}
            <button
              className="btn btn-light"
              type="button"
              onClick={onCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-light-primary"
              type="button"
              onClick={() => {
                if (!name) {
                  setIsError(true);
                } else {
                  if (isCreateMode) {
                    onSaveTopic(name);
                  } else {
                    onSaveTopic(selectedTopic.id, name);
                  }
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TopicModal;
