import http from '../services/http-common';

export default class User {
  static login(data) {
    return http.post('/auth/local', data);
  }

  static fetchUsers(queryParams) {
    return http.get('/users', queryParams);
  }

  static fetchLocation(queryParams) {
    return http.get('/clinician-locations', queryParams);
  }

  static fetchRole() {
    return http.get('/users-permissions/roles');
  }

  static create(body) {
    return http.post('/auth/local/register', body);
  }

  static getUserById(id) {
    return http.get(`/users/${id}`);
  }

  static updateUserById(body, id) {
    return http.put(`/users/${id}`, body);
  }

  static fetchChartInfoByClinicianId(id) {
    return http.get(`/assigned-activities/chart-info-clinician/${id}`);
  }

  static fetchChartInfoByClientId(id) {
    return http.get(`/assigned-activities/chart-info-client/${id}`);
  }

  static fetchActivitiesByClientId(id, queryParams) {
    return http.get(`/assigned-activities/client/${id}`, queryParams);
  }

  static previewAssignedActivity(clientId, id) {
    return http.get(
      `/assigned-activities/preview/client/${clientId}/activity/${id}`
    );
  }

  static updatePassword(body) {
    return http.put('/users/me/update-password', body);
  }

  static forgotPassword(body) {
    return http.post('/auth/forgot-password', body);
  }

  static resetPassword(body) {
    return http.post('/auth/reset-password', body);
  }
}
