import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { SingleSelect } from '../../../../components/Selects';

const AssignActivityForm = ({
  activity,
  usersAvailable,
  handleAssignClient,
  activityId,
  assignedClients,
  deleteActivityId,
}) => {
  const [keyword, setKeyword] = useState('');
  const [filterUsersAvailable, setFilterUserAvailable] = useState([]);

  const handleOnChange = (e) => {
    const searchWord = e.target.value;
    setKeyword(e.target.value);
    if (!searchWord) return setFilterUserAvailable([]);
    const reduceData = mappingUsersData(usersAvailable);
    const adjustUsersAvailable = reduceData.filter((user) =>
      user.title.toLowerCase().includes(searchWord.toLowerCase())
    );
    return setFilterUserAvailable(adjustUsersAvailable);
  };

  const getFullName = (user) => {
    return `${user?.first_name || ''} ${user?.surname || ''}`;
  };

  const renderTopics = () => {
    return (activity?.topics || []).map((topic, index) => {
      return (
        <span key={index} className="badge badge-pill badge-info me-2 mb-1">
          {topic?.name}
        </span>
      );
    });
  };

  const mappingUsersData = (data) => {
    return data?.length > 0
      ? data.map((user) => {
          return {
            key: user.id,
            title: getFullName(user),
          };
        })
      : [];
  };

  return (
    <div className="card mb-10 mb-md-0">
      <div className="card-header py-6">
        <label className="mb-3 text-muted fs-7">Assign to</label>
        <SingleSelect
          placeholder="Select client to assign this activity"
          data={filterUsersAvailable}
          isResetSelected={deleteActivityId}
          getData={handleAssignClient}
          customClassName={'activity-select'}
          customPositionSelectbox={'activity-select-position'}
          hasAssignBtn={true}
          searchKeyword={keyword}
          searchingUser={handleOnChange}
          hasSearchUser={true}
        />
      </div>
      <div className="card-header bg-light-info align-items-center text-center">
        <div className="flex-column-fluid">
          <span className="fw-bolder fs-4">0</span>
          <small className="d-block fs-9 text-gray-500">
            Total Completions
          </small>
        </div>
        <span className="px-3 flex-column-fluid">/</span>
        <div className="flex-column-fluid">
          <span className="fw-bolder fs-4">{assignedClients?.length || 0}</span>
          <small className="d-block fs-9 text-gray-500">Total Assigned</small>
        </div>
      </div>
      <div className="card-header p-0 border-0">
        <Link to={`/admin/edit-activity/${activityId}`} className="w-100">
          {activity?.activity_picture?.url && (
            <img
              className="mb-0 w-100 img-fluid"
              style={{ maxHeight: 350 }}
              src={activity.activity_picture.url}
              alt=""
            />
          )}
        </Link>
      </div>
      <div className="card-body py-10 px-6">
        <Link
          className="text-muted fs-7"
          to={`/admin/edit-activity/${activityId}`}
        >
          {activity?.code}
        </Link>
        <h4 className="fw-bold">
          <Link
            className="ellipsis-2rows text-dark"
            to={`/admin/edit-activity/${activityId}`}
          >
            {activity?.title}
          </Link>
        </h4>
        {activity?.description.startsWith('<') ? (
          <div className="html-render">
            {ReactHtmlParser(activity?.description)}
          </div>
        ) : (
          <p>{activity?.description}</p>
        )}
        <div className="me-n2">{renderTopics()}</div>
      </div>
      <div className="card-footer text-end py-3">
        <Link
          className="btn btn-link text-info"
          to={`/admin/edit-activity/${activityId}`}
        >
          View detail
          <span className="svg-icon me-0 ms-2 opacity-50">
            <img src="/images/icons/next.svg" alt="right-icon" />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default AssignActivityForm;
