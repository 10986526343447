import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { PasswordRegex } from '../../../../utils/constants';

function ChangePWForm({ onChangePW }) {
  const schema = yup.object().shape({
    current_password: yup
      .string()
      .matches(
        PasswordRegex,
        'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
      )
      .required('Current password is required'),
    new_password: yup
      .string()
      .matches(
        PasswordRegex,
        'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
      )
      .required('New password is required'),
    re_password: yup
      .string()
      .matches(
        PasswordRegex,
        'Please ensure your password contains at least 6 characters and includes one capital letter and one non alphanumeric character'
      )
      .required('Retype new password is required')
      .oneOf(
        [yup.ref('new_password'), null],
        'The password you have entered do not match. Please try again.'
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      current_password: '',
      new_password: '',
      re_password: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { re_password, ...transformData } = data;
    onChangePW(transformData);
  };

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-350px">
      <div className="card mb-10 mb-lg-0">
        <div className="card-body py-10 px-6 py-lg-20">
          <div className="d-flex flex-column px-3">
            <form
              className="form w-100"
              noValidate="novalidate"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="pb-5 pb-lg-15">
                <h3 className="fw-bolder text-dark fs-2x">Update password</h3>
                <div className="text-danger">
                  Please ensure your password contains at least 6 characters,
                  including one capital letter and one non alphanumeric
                  character
                </div>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">
                    Current password
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="passwordCurrent"
                  autoComplete="off"
                  {...register('current_password')}
                />
                <span className="invalid-feedback">
                  {errors['current_password']?.message}
                </span>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">
                    New password
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="passwordNew"
                  autoComplete="off"
                  {...register('new_password')}
                />
                <span className="invalid-feedback">
                  {errors['new_password']?.message}
                </span>
              </div>
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">
                    Retype new password
                  </label>
                </div>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="password"
                  name="passwordRetype"
                  autoComplete="off"
                  {...register('re_password')}
                />
                <span className="invalid-feedback">
                  {errors['re_password']?.message}
                </span>
              </div>
              <div className="pb-lg-0 pb-5">
                <button
                  className="btn btn-info fw-bolder fs-6 px-8 py-4 my-3 me-3"
                  type="submit"
                >
                  Update password
                </button>
                <button
                  className="btn btn-light-info fw-bolder fs-6 px-8 py-4 my-3"
                  type="button"
                  onClick={() => reset()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePWForm;
