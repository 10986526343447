import React, { useState, useEffect } from 'react';
import Activities from './Components/Activities';
import DailyQuote from './Components/DailyQuote';
import Statistics from './Components/Statistics';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAdminDashboardInfo,
  selectAdminTotal,
} from '../../../../features/admin/dashboard';

const Dashboard = () => {
  const dispatch = useDispatch();
  const totalAdminDashboard = useSelector(selectAdminTotal);

  useEffect(() => {
    dispatch(fetchAdminDashboardInfo());
  }, []);
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <Statistics totalAdminDashboard={totalAdminDashboard} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
