import React from 'react';
import { get } from 'lodash';
import { displayIconSort, formatDate } from '../../../../../utils/helper';
import { useHistory, Link } from 'react-router-dom';
import Pagination from '../../../../../components/Pagination';
import { useSortableData } from '../../../../../../hooks/useSortableData';

function ActivityTable({
  activities,
  onPageClick,
  onChangeSize,
  totalActivity,
  onClickTopic,
}) {
  const history = useHistory();

  const handleNavigate = (activity) => () => {
    history.push(`/admin/activity-summary/${activity.id}`);
  };

  const {
    items: dataTable,
    requestSort,
    sortConfig,
  } = useSortableData(activities);

  return (
    <div className="card">
      <div className="card-body">
        <div className="card">
          <div className="card-body p-0">
            <div
              id="kt_datatable_example_2_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="mobile-table">
                <table
                  className="table table-striped table-row-bordered gy-5 gs-7 align-middle w-100"
                  id="kt_datatable_example_2"
                >
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800">
                      <th onClick={() => requestSort('title')}>
                        Activities {displayIconSort('title', sortConfig)}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => requestSort('code')}
                      >
                        Code {displayIconSort('code', sortConfig)}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => requestSort('countAssigned')}
                      >
                        Assigned {displayIconSort('countAssigned', sortConfig)}
                      </th>
                      <th
                        className="text-center"
                        onClick={() => requestSort('countCompleted')}
                      >
                        Completed
                        {displayIconSort('countCompleted', sortConfig)}
                      </th>
                      <th onClick={() => requestSort('created_at')}>
                        Created {displayIconSort('created_at', sortConfig)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable.map((item, index) => (
                      <tr key={index}>
                        <td
                          className="min-w-400px"
                          onClick={handleNavigate(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-75px symbol-2by3">
                              <img
                                src={`${
                                  get(item, 'activity_picture.url') ||
                                  '/images/illustrations/01.png'
                                }`}
                                alt
                              />
                            </div>
                            <div className="ms-5">
                              <Link
                                className="ellipsis-2rows fw-bolder mb-0"
                                to={`/admin/activity-summary/${item.id}`}
                              >
                                {get(item, 'title')}
                              </Link>
                              <div className="mt-4">
                                <div className="d-flex overflow-auto">
                                  {get(item, 'topics', []).map(
                                    (topic, index) => (
                                      <Link
                                        to="#"
                                        key={index}
                                        className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          onClickTopic(e, {
                                            id: topic.id,
                                            value: topic.name,
                                          });
                                        }}
                                      >
                                        {topic.name}
                                      </Link>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="min-w-100px text-center">
                          {get(item, 'code')}
                        </td>
                        <td className="min-w-150px text-center text-gray-500">
                          {get(item, 'countAssigned')}
                        </td>
                        <td className="min-w-150px text-center text-gray-500">
                          {get(item, 'countCompleted')}
                        </td>
                        <td className="min-w-150px text-gray-500">
                          {formatDate(get(item, 'created_at'))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalActivity > 0 && (
                <Pagination
                  onChangeSize={onChangeSize}
                  onPageClick={onPageClick}
                  total={totalActivity}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityTable;
