import React, { useEffect, useMemo } from 'react';
import UserForm from './Components/UserForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUser,
  fetchLocation,
  fetchRole,
  fetchUsers,
  selectLocations,
  selectRoles,
  selectUserList,
} from '../../../../../features/user';
import { useHistory } from 'react-router-dom';
import { filterRole } from '../../../../utils/helper';
import { UserType } from '../../../../utils/constants';
import { selectAuthUser } from '../../../../../features/user/auth';
import { get } from 'lodash';

function AddUser() {
  const history = useHistory();

  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const roles = useSelector(selectRoles);
  const clinician = useSelector(selectUserList);
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    let locationQueryParams;
    let userQueryParams;
    if (get(authUser, 'role.id') === UserType.SupperAdmin) {
      userQueryParams = {
        role: UserType.Clinician,
        blocked: false,
      };
    } else {
      userQueryParams = {
        role: UserType.Clinician,
        clinician_location: get(authUser, 'clinician_location.id'),
      };
      locationQueryParams = {
        id: get(authUser, 'clinician_location.id'),
      };
    }

    dispatch(fetchLocation(locationQueryParams));
    dispatch(fetchRole());
    dispatch(fetchUsers(userQueryParams));
  }, []);

  const onHandleAddUser = (data) => {
    dispatch(addUser(data));
  };

  const onHandleCancel = () => {
    history.push('/admin/user-list');
  };

  const filterRoles = useMemo(() => {
    return filterRole(roles, get(authUser, 'role.id', ''));
  }, [roles, authUser]);

  return (
    <UserForm
      onHandleSubmit={onHandleAddUser}
      onHandleCancel={onHandleCancel}
      locations={locations}
      roles={filterRoles}
      clinician={clinician}
    />
  );
}

export default AddUser;
