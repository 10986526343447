import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import Dashboard from './Dashboard';
import UserList from './Users/UserList';
import ClinicianDetail from './Users/ClinicianDetail';
import AdminDetail from './Users/AdminDetail';
import ClientDetail from './Users/ClientDetail';
import AddUser from './Users/AddUser';
import ActivityLibrary from './ActivityLibrary';
import ActivityForm from './ActivityLibrary/ActivityForm';
import ActivitySummary from './ActivityLibrary/ActivitySummary';
import Account from './Account';
import Cms from './Cms';
import Sidebar from './Layout/Sidebar';
import Header from '../../components/Header';
import { UserType } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { getTitle } from '../../../features/admin/dailyQuotes';
import ScrollTop from '../../components/ScrollToTop';
import useAnimationMobileMenu from '../../../hooks/use-animation-mobile-menu';

const Admin = () => {
  const { isScrollAnimated } = useAnimationMobileMenu();
  const location = useLocation();
  const history = useHistory();

  const routers = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      path: '/admin/dashboard',
      isShowUser: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'Dashboard',
        },
      ],
      component: Dashboard,
    },
    {
      key: 'addUser',
      name: 'Create User',
      path: '/admin/user-list/add',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'User list',
          path: '/admin/user-list',
        },
        {
          name: 'Create User',
        },
      ],
      component: AddUser,
    },
    {
      key: 'userList',
      name: 'User list',
      path: '/admin/user-list',
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'User list',
        },
      ],
      component: UserList,
    },
    {
      key: 'adminDetail',
      name: 'Admin detail',
      path: '/admin/admin-detail/:id',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'User list',
          path: `/admin/user-list?userType=${UserType.Admin}`,
        },
        {
          name: 'Admin details',
        },
      ],
      component: AdminDetail,
    },
    {
      key: 'clinicianDetail',
      name: 'Clinician detail',
      path: '/admin/clinician-detail/:id',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'User list',
          path: `/admin/user-list?userType=${UserType.Clinician}`,
        },
        {
          name: 'Clinician detail',
        },
      ],
      component: ClinicianDetail,
    },
    {
      key: 'clientDetail',
      name: 'Client detail',
      path: '/admin/client-detail/:id',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'User list',
          path: `/admin/user-list?userType=${UserType.Client}`,
        },
        {
          name: 'Client detail',
        },
      ],
      component: ClientDetail,
    },
    {
      key: 'activityLiblary',
      name: 'Activity Library',
      path: '/admin/activity-library',
      isHideHeader: true,
      component: ActivityLibrary,
    },
    {
      key: 'createActivity',
      name: 'Create activity',
      path: '/admin/create-activity',
      needBack: true,
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'Activities',
          path: '/admin/activity-library',
        },
        {
          name: 'Create activity',
        },
      ],
      component: ActivityForm,
    },
    {
      key: 'activitySummary',
      name: 'Activity Summary',
      path: '/admin/activity-summary/:id',
      isHideHeader: true,
      component: ActivitySummary,
    },
    {
      key: 'cms',
      name: useSelector(getTitle),
      path: '/admin/cms',
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'Content management system',
          path: '/admin/cms',
        },
        {
          name: 'Daily quotes',
        },
      ],
      component: Cms,
    },
    {
      key: 'account',
      name: 'My account',
      path: '/admin/account',
      component: Account,
    },
    {
      key: 'editActivity',
      name: 'Activity Details',
      breadCrumbs: [
        {
          name: 'Home',
          path: '/admin/dashboard',
        },
        {
          name: 'Activities',
          path: '/admin/activity-library',
        },
        {
          name: 'Activity Details',
        },
      ],
      path: '/admin/edit-activity/:activityId',
      needBack: true,
      component: ActivityForm,
    },
  ];

  useEffect(() => {
    if (location.pathname.endsWith('/admin')) {
      history.push('/admin/dashboard');
    }
  }, []);

  const getWrapperClassName = () => {
    if (
      ['/admin/user-list', '/admin/activity-library'].includes(
        location.pathname
      )
    ) {
      return 'header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed toolbar-enabled aside-secondary-disabled';
    } else if (location.pathname.startsWith('/admin/admin-detail/')) {
      return 'header-fixed header-tablet-and-mobile-fixed aside-secondary-enabled .aside-enabled aside-fixed';
    } else if (
      location.pathname.startsWith('/admin/clinician-detail/') ||
      location.pathname.startsWith('/admin/client-detail/')
    ) {
      return 'header-fixed header-tablet-and-mobile-fixed toolbar-enabled aside-secondary-enabled .aside-enabled aside-fixed';
    }
    return 'header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed aside-secondary-disabled';
  };

  const onScroll = () => {
    const kt_body = document.getElementById('kt_body');
    const kt_back = document.getElementById('kt_back');
    if (window.scrollY / window.innerHeight > 0.5) {
      kt_body.setAttribute('data-kt-scrolltop', 'on');
      kt_body.setAttribute('data-kt-sticky-header', 'on');
      if (kt_back) {
        kt_back.classList.add('kt-back-mobile');
      }
    } else {
      kt_body.setAttribute('data-kt-scrolltop', 'off');
      kt_body.setAttribute('data-kt-sticky-header', 'off');
      if (kt_back) {
        kt_back.classList.remove('kt-back-mobile');
      }
    }
  };
  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      className={getWrapperClassName()}
      id="kt_body"
      data-kt-scrolltop="off"
      data-kt-sticky-header="off"
    >
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header
              routers={routers}
              hideHeaderUser={false}
              isScrollAnimated={isScrollAnimated}
            />
            <Switch>
              {routers.map((router) => {
                return (
                  <Route
                    key={router.key}
                    path={router.path}
                    component={router.component}
                  />
                );
              })}
            </Switch>
            <ScrollTop />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
