import React, { useState } from 'react';

const UploadImage = ({ getImage, defaultImage, isDisabled = false }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const handleSelectImage = (file) => {
    getImage(file);
    setSelectedImage(file);
  };

  return (
    <div
      className="image-input image-input-empty symbol symbol-150px"
      style={{ paddingLeft: 20 }}
    >
      {!isDisabled && (
        <label
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          data-bs-dismiss="click"
          title="Change picture"
        >
          <i className="bi bi-pencil-fill fs-7"></i>
          <input
            type="file"
            name="avatar"
            accept=".png, .jpg, .jpeg, .mov, .mp4"
            onChange={(event) => {
              handleSelectImage(event.target.files[0]);
            }}
          />
          <input type="hidden" name="avatar_remove" />
        </label>
      )}
      {(defaultImage || selectedImage) && (
        <img
          alt="activity image"
          src={
            selectedImage ? URL.createObjectURL(selectedImage) : defaultImage
          }
          style={{ objectFit: 'cover' }}
        />
      )}
    </div>
  );
};

export default UploadImage;
