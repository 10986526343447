import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectAvatarResponse } from '../../../../../../features/user';
import { UserType } from '../../../../../utils/constants';
import useDidMountEffect from '../../../../../../hooks/useDidMountEffect ';
import { SingleSelect } from '../../../../../components/Selects';

function DetailTab({
  onHandleSubmit,
  onHandleCancel,
  userDetail,
  locations,
  roles,
  clinician,
}) {
  const avatarResponse = useSelector(selectAvatarResponse);

  const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    surname: yup.string().required('Surname is required'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Email Address is required'),
    mobile_number: yup.string().required('Mobile number is required'),
    role: yup.string().required('Account type is required'),
    clinician_location: yup.string().required('Clinician location is required'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      first_name: get(userDetail, 'first_name') || '',
      surname: get(userDetail, 'surname') || '',
      mobile_number: get(userDetail, 'mobile_number') || '',
      email: get(userDetail, 'email') || '',
      role: get(userDetail, 'role.id') || '',
      activated: get(userDetail, 'activated'),
      clinician: get(userDetail, 'clinician.id') || '',
      clinician_location: get(userDetail, 'clinician_location.id') || '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // check condition
    if (data.role == UserType.Client && !data.clinician) {
      return;
    } else {
      let transformData = {
        ...data,
        username: data.email,
        clinician: data.role == UserType.Client ? data.clinician : null,
      };
      if (avatarResponse) {
        transformData = { ...transformData, profile_picture: avatarResponse };
      }
      onHandleSubmit(transformData);
    }
  };

  const handleDeactivate = () => {
    onHandleSubmit({ ...getValues(), blocked: true });
  };

  useDidMountEffect(() => {
    const filteredClinician = clinician.filter(
      (item) =>
        get(item, 'clinician_location.id') == watch('clinician_location')
    );
    if (filteredClinician) {
      setValue('clinician', get(filteredClinician[0], 'id'));
    } else {
      setValue('clinician', '');
    }
  }, [watch('clinician_location'), watch('role')]);

  const handleSelectValue = (value, type) => {
    switch (type) {
      case 'account_type': {
        setValue('role', value.id);
        break;
      }
      case 'clinician_location': {
        setValue('clinician_location', value.id);
        break;
      }
      case 'clinician': {
        setValue('clinician', value.id);
        break;
      }
      default:
        break;
    }
  };

  const mappingDataSelectAccountType = (data) => {
    return (
      data &&
      data.map((role) => {
        return { ...role, title: role.name };
      })
    );
  };

  const mappingDataSelectLocation = (data) => {
    return (
      data &&
      data.map((location) => {
        return { ...location, title: location.name };
      })
    );
  };

  const mappingDataSelectClinician = (data) => {
    return (
      data &&
      data.map((clinician) => {
        return {
          ...clinician,
          title: `${clinician.first_name} ${clinician.surname}`,
        };
      })
    );
  };

  return (
    <>
      <form
        className="form d-flex flex-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card-body mw-800px py-20">
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">First name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('first_name')}
                />
                <span className="invalid-feedback">
                  {errors['first_name']?.message}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Surname</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('surname')}
                />
                <span className="invalid-feedback">
                  {errors['surname']?.message}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Email Address</label>
            <div className="col-lg-9">
              <div className="input-group input-group-lg input-group-solid">
                <span className="input-group-text pe-0">
                  <i className="la la-at fs-4" />
                </span>
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('email')}
                  placeholder="mail@example.com"
                />
              </div>
              <span className="invalid-feedback">
                {errors['email']?.message}
              </span>
              <div className="form-text">
                Email will not be publicly displayed.{' '}
                <a className="fw-bold" href="#">
                  Learn more
                </a>
                .
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Mobile number</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  {...register('mobile_number')}
                />
                <span className="invalid-feedback">
                  {errors['mobile_number']?.message}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Current account status
            </label>
            <div className="col-lg-9">
              <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                <input
                  className="form-check-input"
                  id="userState"
                  type="checkbox"
                  {...register('activated')}
                />
                <label
                  className="form-check-label"
                  htmlFor="userState"
                  data-on="Active"
                  data-off="Inactive"
                />
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Account type</label>
            <div className="col-lg-9">
              <SingleSelect
                data={mappingDataSelectAccountType(roles)}
                defaultValue={mappingDataSelectAccountType(roles).find(
                  (item) => item.id === get(userDetail, 'role.id')
                )}
                getData={(value) => handleSelectValue(value, 'account_type')}
                placeholder={'Select Type...'}
              />
            </div>
          </div>

          {watch('role') == UserType.Client && (
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Clinician</label>
              <div className="col-lg-9">
                <SingleSelect
                  data={mappingDataSelectClinician(clinician)}
                  defaultValue={mappingDataSelectClinician(clinician).find(
                    (item) => item.id === get(userDetail, 'clinician.id')
                  )}
                  getData={(value) => handleSelectValue(value, 'clinician')}
                  placeholder={'Select Clinician...'}
                />
                <span className="invalid-feedback">
                  {errors['clinician']?.message}
                </span>
              </div>
            </div>
          )}

          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Clinician location
            </label>
            <div className="col-lg-9">
              <SingleSelect
                data={mappingDataSelectLocation(locations)}
                defaultValue={mappingDataSelectLocation(locations).find(
                  (item) => item.id === get(userDetail, 'clinician_location.id')
                )}
                getData={(value) =>
                  handleSelectValue(value, 'clinician_location')
                }
                placeholder={'Select Location...'}
              />
              <span className="invalid-feedback">
                {errors['clinician_location']?.message}
              </span>
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-9">
              <button
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
                type="submit"
              >
                Save Changes
              </button>
              <button
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                type="button"
                onClick={onHandleCancel}
              >
                Cancel
              </button>
              <button
                className="btn btn-light-danger fw-bolder px-6 py-3 float-end"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modal-warning"
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* Warning modal */}
      <div className="modal fade" id="modal-warning" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-exclamation-circle text-danger fs-5x" />
                </div>
                <h3>Are you sure you want to do this?</h3>
                <p>This action can not be undone</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="w-100px btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="w-100px btn btn-light-primary"
                type="button"
                data-bs-dismiss="modal"
                onClick={handleDeactivate}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailTab;
