import React, { useState } from 'react';
import ActivityCard from './ActivityCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSortableData } from '../../../../../../hooks/useSortableData';
import { displayIconSort } from '../../../../../utils/helper';

function ActivityGrid({
  latestActivities,
  activities,
  onClickTopic,
  handleLoadMore,
  keywordSearching,
}) {
  const sortByList = [
    { label: 'Code', key: 'code' },
    { label: 'Title', key: 'title' },
    { label: 'Topics', key: 'topics.length' },
    { label: 'Assigned', key: 'countAssigned' },
    { label: 'Submitted', key: 'countCompleted' },
    { label: 'Created date', key: 'created_at' },
  ];

  const [isShowSort, setIsShowSort] = useState(false);
  const [selectedOption, setSelectedOption] = useState(sortByList[0]);

  const {
    items: dataGrid,
    requestSort,
    sortConfig,
  } = useSortableData(activities);

  const onSelectSort = (option) => {
    requestSort(option.key);
    setSelectedOption(option);
  };

  return (
    <>
      {keywordSearching && keywordSearching.length >= 3 ? (
        <></>
      ) : (
        <div className="d-flex flex-column">
          <h3 className="fw-bolder text-dark my-5">Latest Activities</h3>
          <div className="row row-cols-sm-2 row-cols-md-3">
            {latestActivities.map((activity, index) => (
              <div className="p-5" key={activity.id}>
                <ActivityCard activity={activity} onClickTopic={onClickTopic} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <h3 className="fw-bolder text-dark mt-10">Activity Library</h3>
        <div>
          <button
            className="btn btn-link text-success"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            style={{
              position: 'relative',
            }}
            onClick={() => setIsShowSort(!isShowSort)}
          >
            <span>{`Sort by ${selectedOption.label}`}</span>
            {displayIconSort(selectedOption.key, sortConfig)}
            <div
              className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px p-3 ${
                isShowSort ? 'show' : ''
              }`}
              style={{
                zIndex: 105,
                position: 'absolute',
                inset: '0px auto auto 0px',
                margin: '0px',
                top: '35px',
              }}
              data-kt-menu="true"
            >
              {sortByList.map((option, index) => (
                <div
                  className="menu-item"
                  key={index}
                  onClick={() => onSelectSort(option)}
                >
                  <span
                    key={`sortByList-${index}`}
                    className={`menu-link p-3 ${
                      index === 0 && 'pulse-check-option text-dark fw-bolder'
                    }`}
                  >
                    {option.label}
                  </span>
                </div>
              ))}
            </div>
          </button>
        </div>
        <InfiniteScroll
          dataLength={dataGrid.length}
          next={handleLoadMore}
          hasMore={true}
        >
          <div className="row row-cols-sm-2 row-cols-md-3">
            {dataGrid?.length > 0 &&
              dataGrid.map((activity, index) => (
                <div className="p-5" key={`activity_${index}`}>
                  <ActivityCard
                    activity={activity}
                    onClickTopic={onClickTopic}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default ActivityGrid;
