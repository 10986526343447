import * as React from 'react';
import Modal from '@mui/material/Modal';

import './styles.scss';

const ModalConfirm = (props) => {
  return (
    <div>
      <Modal open={props.isOpen} onClose={props.handleClose}>
        <div className="modal-content modal-centered">
          <div className="modal-body">
            <div className="text-center">
              <div className="my-10">{props.CustomIcon ? <props.CustomIcon color="success" sx={{ fontSize: 40 }} /> : <i className="bi bi-exclamation-circle text-danger fs-5x"></i>}</div>
              <h3>{props.modalTitle}</h3>
              <p>{props.modalNotes}</p>
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-center">
            <button className="w-100px btn btn-light" type="button" data-bs-dismiss="modal" onClick={props.handleClose}>
              Cancel
            </button>
            <button className="w-100px btn btn-light-primary" type="button" onClick={props.confirm}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
