import React from 'react';

const BannerAuth = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
        <div className="d-flex flex-column flex-lg-row-auto bg-info w-lg-600px pt-lg-0">
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            <a className="mb-6" href="/">
              <img className="h-30px h-lg-60px" alt="Logo" src="/images/logos/logo-default.svg" />
            </a>
          </div>
          <div
            className="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-250px"
            style={{
              backgroundImage: 'url("/images/illustrations/winner.png")',
            }}
          ></div>
        </div>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default BannerAuth;
