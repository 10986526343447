import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  fetchUsers,
  selectTotalUser,
  selectUserList,
} from '../../../../../features/user';
import { selectAuthUser } from '../../../../../features/user/auth';
import {
  DebounceTimeDefault,
  initialPagination,
  UserType,
} from '../../../../utils/constants';
import {
  displayIconSort,
  formatDate,
  formatDateTime,
} from '../../../../utils/helper';
import SVG from 'react-inlinesvg';
import Pagination from '../../../../components/Pagination';
import { debounce } from 'lodash';
import { useSortableData } from '../../../../../hooks/useSortableData';

const UserList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userType = parseInt(query.get('userType'));

  const users = useSelector(selectUserList);
  const totalUser = useSelector(selectTotalUser);
  const userLogin = useSelector(selectAuthUser);

  const { items: dataTable, requestSort, sortConfig } = useSortableData(users);

  const [keyword, setKeyword] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const defaultTab = useMemo(() => {
    if (get(userLogin, 'role.id') === UserType.SupperAdmin) {
      return UserType.Admin;
    } else if (get(userLogin, 'role.id') === UserType.Admin) {
      return UserType.Clinician;
    } else {
      return UserType.Client;
    }
  }, [userLogin]);
  const [tab, setTab] = useState(userType || defaultTab);
  const [pagination, setPagination] = useState({
    ...initialPagination,
    _limit: 50,
  });

  const defaultQueryParams = useMemo(() => {
    if (get(userLogin, 'role.id') === UserType.SupperAdmin) {
      return { blocked: false, ...pagination };
    } else if (get(userLogin, 'role.id') === UserType.Admin) {
      return {
        clinician_location: get(userLogin, 'clinician_location.id'),
        blocked: false,
        ...pagination,
      };
    } else {
      return {
        clinician: get(userLogin, 'id'),
        blocked: false,
        ...pagination,
      };
    }
  }, [userLogin, pagination]);

  const queryParams = useMemo(() => {
    return {
      ...defaultQueryParams,
      role: tab,
      _q: searchQuery,
    };
  }, [defaultQueryParams, tab, searchQuery]);

  useEffect(() => {
    if (queryParams) {
      dispatch(fetchUsers(queryParams));
    }
  }, [queryParams]);

  const getLinkDetail = (user) => {
    if (tab === UserType.Admin) {
      return `/admin/admin-detail/${user.id}`;
    } else if (tab === UserType.Clinician) {
      return `/admin/clinician-detail/${user.id}`;
    }
    return `/admin/client-detail/${user.id}`;
  };

  const getCellData = (user) => {
    if (tab === UserType.Client) {
      const firstname = get(user, 'clinician.first_name', '');
      const surname = get(user, 'clinician.surname', '');
      const id = get(user, 'clinician.id', '');
      if (get(userLogin, 'role.id') === UserType.Clinician) {
        return <td>{`${firstname} ${surname}`}</td>;
      } else {
        return (
          <td>
            <Link to={`/admin/clinician-detail/${id}`}>
              {`${firstname} ${surname}`}
            </Link>
          </td>
        );
      }
    } else {
      return <td>{get(user, 'clinician_location.name')}</td>;
    }
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      _start: pagination._limit * page,
    });
  };

  const onChangeSize = (size) => {
    setPagination({ ...pagination, _start: 0, _limit: size });
  };

  const handleOnSearch = (inputValue) => {
    setSearchQuery(inputValue);
  };

  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="toolbar mt-8 mt-lg-0" id="kt_toolbar">
        <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
            {get(userLogin, 'role.id') === UserType.SupperAdmin && (
              <button
                className={`btn btn-active-accent fw-bolder ${
                  tab === UserType.Admin ? 'active' : ''
                }`}
                onClick={() => setTab(UserType.Admin)}
              >
                Admins
              </button>
            )}

            {(get(userLogin, 'role.id') === UserType.SupperAdmin ||
              get(userLogin, 'role.id') === UserType.Admin) && (
              <button
                className={`btn btn-active-accent fw-bolder ms-3 ${
                  tab === UserType.Clinician ? 'active' : ''
                }`}
                onClick={() => setTab(UserType.Clinician)}
              >
                Clinicians
              </button>
            )}
            <button
              className={`btn btn-active-accent fw-bolder ms-3 ${
                tab === UserType.Client ? 'active' : ''
              }`}
              onClick={() => setTab(UserType.Client)}
            >
              Clients
            </button>
          </div>
          <div className="ms-md-auto">
            <button
              className="btn btn-primary"
              onClick={() =>
                history.push({
                  pathname: '/admin/user-list/add',
                  state: { userType: tab },
                })
              }
            >
              <i className="fas fa-plus opacity-50 me-2"></i>
              Create User
            </button>
          </div>
        </div>
      </div>
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              {/**Search bar */}
              <div className="d-flex align-items-center position-relative my-1">
                <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
                  <SVG src="/images/icons/search.svg" />
                </span>
                <input
                  className="form-control form-control-solid w-100 mw-350px ps-15 ms-n12"
                  type="text"
                  data-kt-docs-table-filter="search"
                  placeholder="Search (first name, surname or email)"
                  value={keyword}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
              {/**End Block Search bar */}
              <div
                id="kt_datatable_example_2_wrapper"
                className="dataTables_wrapper dt-bootstrap4 no-footer"
              >
                <div className="mobile-table">
                  <table
                    className="table table-striped table-row-bordered gy-5 gs-7 w-100 align-middle"
                    id="kt_datatable_example_2"
                  >
                    <thead>
                      <tr className="fw-bold fs-6 text-gray-800">
                        <th
                          className="min-w-200px sorting sorting_asc"
                          onClick={() => requestSort(['first_name', 'surname'])}
                        >
                          Name
                          {displayIconSort(
                            ['first_name', 'surname'],
                            sortConfig
                          )}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() =>
                            requestSort([
                              'clinician.first_name',
                              'clinician.surname',
                            ])
                          }
                        >
                          {`${
                            tab === UserType.Client ? 'Clinician' : 'Location'
                          }`}
                          {displayIconSort(
                            ['clinician.first_name', 'clinician.surname'],
                            sortConfig
                          )}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('mobile_number')}
                        >
                          Mobile
                          {displayIconSort('mobile_number', sortConfig)}
                        </th>
                        <th
                          className="min-w-200px "
                          onClick={() => requestSort('email')}
                        >
                          Email
                          {displayIconSort('email', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('last_activate')}
                        >
                          Last active
                          {displayIconSort('last_activate', sortConfig)}
                        </th>
                        <th
                          className="min-w-100px "
                          onClick={() => requestSort('created_at')}
                        >
                          Created
                          {displayIconSort('created_at', sortConfig)}
                        </th>
                        {tab === UserType.Client && (
                          <th
                            className="min-w-125px "
                            onClick={() =>
                              requestSort('assigned_activities.length')
                            }
                          >
                            Activities
                            {displayIconSort(
                              'assigned_activities.length',
                              sortConfig
                            )}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!!dataTable?.length > 0 &&
                        dataTable.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link to={getLinkDetail(user)}>
                                  {`${user.first_name} ${user.surname}`}
                                </Link>
                              </td>
                              {getCellData(user)}
                              <td>{user.mobile_number}</td>
                              <td>
                                <a>{user.email}</a>
                              </td>
                              <td>{formatDateTime(user.last_activate)}</td>
                              <td>{formatDate(user.created_at)}</td>
                              {tab === UserType.Client && (
                                <td>{user.assigned_activities.length}</td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {totalUser > 0 && (
                  <Pagination
                    limit={pagination._limit}
                    onChangeSize={onChangeSize}
                    onPageClick={onPageClick}
                    total={totalUser}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
