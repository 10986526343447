import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { createTopic } from '../../../../features/admin/topic';
import Activities from './Activities';
import Topics from './Topics';
import TopicModal from './Topics/TopicModal';
import { get } from 'lodash';
import { selectAuthUser } from '../../../../features/user/auth';
import { UserType } from '../../../utils/constants';
import SVG from 'react-inlinesvg';

const ShowType = {
  List: 0,
  Grid: 1,
};

const ActivityLibrary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  const [tab, setTab] = useState('activities');
  const [isOpenTopicModal, setOpenTopicModal] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [typeShow, setTypeShow] = useState(ShowType.Grid);

  const renderTabContent = () => {
    switch (tab) {
      case 'activities':
        return <Activities typeShow={typeShow} />;
      case 'topics':
        return <Topics refresh={refresh} />;
      default:
        return null;
    }
  };

  const handleSelectTab = (e, tabName) => {
    e.preventDefault();
    setTab(tabName);
  };

  const getActiveTabClass = (name) => {
    return tab === name ? 'active' : '';
  };

  const onCreate = () => {
    if (tab === 'activities') {
      history.push('/admin/create-activity');
    } else {
      setOpenTopicModal(true);
    }
  };

  const onCreateTopic = async (name) => {
    const result = await dispatch(createTopic({ name }));
    if (get(result, 'payload.data')) {
      doRefresh((prev) => prev + 1);
      setOpenTopicModal(false);
    }
  };

  return (
    <>
      <div className="header bg-info bg-lg-transparent" id="kt_header">
        <div className="container d-flex align-items-stretch justify-content-between">
          <div className="d-none d-lg-block">
            <div className="d-flex align-items-center">
              <h3 className="text-dark fw-bolder my-1 me-5">
                Activity library
              </h3>
            </div>
          </div>
          <div className="d-lg-none">
            <Link to="/admin/dashboard">
              <img
                className="h-30px logo"
                alt="Logo"
                src="/images/logos/logo-compact.svg"
              />
            </Link>
          </div>

          <div className="d-lg-none mt-2 mx-10 text-center flex-row-fluid">
            <h3 className="text-white fw-bolder my-1 ellipsis-1row mx-5">
              Activity library
            </h3>
          </div>
          {tab === 'activities' && (
            <div className="d-flex align-items-center ms-auto">
              <div className="d-none d-lg-block">
                <div className="btn btn-primary" onClick={onCreate}>
                  <i className="fas fa-plus opacity-50 me-2"></i>
                  Create Activities
                </div>
              </div>
            </div>
          )}
          {tab === 'topics' &&
            get(authUser, 'role.id') === UserType.SupperAdmin && (
              <div className="d-flex align-items-center ms-auto">
                <div className="d-none d-lg-block">
                  <div className="btn btn-primary" onClick={onCreate}>
                    <i className="fas fa-plus opacity-50 me-2"></i>
                    Create Topic
                  </div>
                </div>
              </div>
            )}
          <button
            className="btn btn-icon btn-info d-lg-none"
            data-bs-toggle="modal"
            data-bs-target="#kt_mega_menu_modal"
          >
            <span className="svg-icon svg-icon-1  ">
              <SVG src="/images/icons/menu.svg" />
            </span>
          </button>
        </div>
      </div>

      {tab === 'activities' && (
        <div
          className="bg-white shadow-sm d-lg-none py-2 px-4"
          data-kt-sticky="true"
          data-kt-sticky-name="docs-sticky-summary"
          data-kt-sticky-offset="{default: '200px'}"
          data-kt-sticky-width="100%"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="55px"
          data-kt-sticky-animation="true"
          data-kt-sticky-zindex={95}
          style={{ animationDuration: '0.3s' }}
          id="kt_back"
        >
          <div className="d-flex align-items-center overflow-auto">
            <div className="ms-auto">
              <div className="btn btn-primary" onClick={onCreate}>
                <i className="fas fa-plus opacity-50 me-2"></i>
                Create Activities
              </div>
            </div>
          </div>
        </div>
      )}
      {tab === 'topics' && get(authUser, 'role.id') === UserType.SupperAdmin && (
        <div className="bg-white shadow-sm d-lg-none py-2 px-4">
          <div className="d-flex align-items-center overflow-auto">
            <div className="ms-auto">
              <div className="btn btn-primary" onClick={onCreate}>
                <i className="fas fa-plus opacity-50 me-2"></i>
                Create Topic
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-column flex-column-fluid">
        <div className="toolbar pt-8 pt-lg-0" id="kt_toolbar">
          <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1 w-100">
              <button
                className={`btn btn-active-accent fw-bolder ${getActiveTabClass(
                  'activities'
                )}`}
                onClick={(e) => handleSelectTab(e, 'activities')}
              >
                Activities
              </button>

              <button
                className={`btn btn-active-accent fw-bolder ms-3 ${getActiveTabClass(
                  'topics'
                )}`}
                onClick={(e) => handleSelectTab(e, 'topics')}
              >
                Topics
              </button>

              {tab === 'activities' && (
                <div className="ms-auto">
                  <ul className="nav flex-nowrap">
                    <li className="nav-item mb-1">
                      <button
                        className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode me-5 ${
                          typeShow === ShowType.Grid ? 'active' : ''
                        }`}
                        onClick={() => setTypeShow(ShowType.Grid)}
                      >
                        <span className="svg-icon svg-icon-4 svg-icon-dark ">
                          <img src="/images/icons/activity-grid.svg" />
                        </span>
                      </button>
                    </li>
                    <li className="nav-item mb-1">
                      <button
                        className={`nav-link h-40px w-40px btn btn-custom btn-icon rounded-1 btn-view-mode ${
                          typeShow === ShowType.List ? 'active' : ''
                        }`}
                        onClick={() => setTypeShow(ShowType.List)}
                      >
                        <span className="svg-icon svg-icon-4 svg-icon-dark">
                          <img
                            src="/images/icons/activity-grid-long.svg"
                            style={{ width: 16, height: 16 }}
                          />
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">{renderTabContent()}</div>
        </div>
      </div>

      <TopicModal
        isCreateMode={true}
        isOpenTopicModal={isOpenTopicModal}
        onCloseModal={() => setOpenTopicModal(false)}
        onSaveTopic={(name) => onCreateTopic(name)}
      />
    </>
  );
};

export default ActivityLibrary;
