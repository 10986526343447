import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { searchActivitiesByClientId } from '../../../../../../features/admin/activity';
import {
  createAssignedActivity,
  deleteAssignedActivity,
} from '../../../../../../features/admin/assignedActivity';
import {
  fetchActivitiesByClientId,
  resetPreviewActivity,
} from '../../../../../../features/user';
import { selectAuthUser } from '../../../../../../features/user/auth';
import { initialPagination } from '../../../../../utils/constants';
import SearchActivities from './SearchActivities';
import TableActivities from './TableActivities';
import { get, findIndex } from 'lodash';

function ActivitiesTab() {
  const { id: userDetailId } = useParams();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  const [pagination, setPagination] = useState(initialPagination);
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    const data = {
      id: userDetailId,
      queryParams: pagination,
    };
    dispatch(fetchActivitiesByClientId(data));
    return () => {
      dispatch(resetPreviewActivity());
    };
  }, [pagination]);

  const onChangeSize = (size) => {
    setPagination({ ...pagination, _start: 0, _limit: size });
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      _start: pagination._limit * page,
    });
  };

  const onDeleteAssignedActivity = async (selectedActivity) => {
    const result = await dispatch(
      deleteAssignedActivity(selectedActivity)
    ).unwrap();
    if (get(result, 'data.activity.id')) {
      const index = findIndex(searchResult, { id: result.data.activity.id });
      if (index > -1) {
        const { assigned_activity_id, ...rest } = searchResult[index];
        searchResult.splice(index, 1, rest);
        setSearchResult([...searchResult]);
      }
      const data = {
        id: userDetailId,
        queryParams: pagination,
      };
      dispatch(fetchActivitiesByClientId(data));
    }
  };

  const handleAssignActivity = async (id) => {
    const data = {
      assigned_user: userDetailId,
      assigned_by: authUser.id,
      activity: id,
    };

    const result = await dispatch(createAssignedActivity(data)).unwrap();
    if (get(result, 'data.activity.id')) {
      const index = findIndex(searchResult, { id: result.data.activity.id });
      if (index > -1) {
        searchResult.splice(index, 1, {
          ...searchResult[index],
          assigned_activity_id: result.data.id,
        });
        setSearchResult([...searchResult]);
      }
      const data = {
        id: userDetailId,
        queryParams: pagination,
      };
      dispatch(fetchActivitiesByClientId(data));
    }
  };

  const searchActivity = async (data) => {
    const result = await dispatch(searchActivitiesByClientId(data)).unwrap();
    if (get(result, 'activities')) {
      setSearchResult(result.activities);
    }
  };

  const clearSearchResult = () => {
    setSearchResult([]);
  };

  return (
    <>
      <SearchActivities
        handleAssignActivity={handleAssignActivity}
        handleRemoveActivity={onDeleteAssignedActivity}
        searchResult={searchResult}
        searchActivity={searchActivity}
        clearSearchResult={clearSearchResult}
      />
      <TableActivities
        onChangeSize={onChangeSize}
        onPageClick={onPageClick}
        searchActivity={searchActivity}
        onDeleteAssignedActivity={onDeleteAssignedActivity}
      />
    </>
  );
}

export default ActivitiesTab;
