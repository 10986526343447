import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOptionChart } from '../../../../../utils/chartHelper';
import { selectChartInfoByClinicianId } from '../../../../../../features/user';
import ChartEngagement from '../../../../../components/ChartEngagement';
import { defaultChartValue } from '../../../../../utils/constants';
import { get } from 'lodash';

function EngagementTab() {
  const chartInfo = useSelector(selectChartInfoByClinicianId);

  const [clientChart, setClientChart] = useState(defaultChartValue);
  const [assignedChart, setAssignedChart] = useState(defaultChartValue);
  const [completedChart, setCompletedChart] = useState(defaultChartValue);

  // note: need to check chartInfo when screen has default tab is apex-chart
  //client
  useEffect(() => {
    if (get(chartInfo, 'patient')) {
      const total = get(chartInfo, 'patient.total', 0);
      const today = get(chartInfo, 'patient.today', 0);
      const yesterday = get(chartInfo, 'patient.yesterday', 0);
      const week = get(chartInfo, 'patient.week', 0);
      const month = get(chartInfo, 'patient.month', 0);

      const datasets = [
        {
          name: 'Today',
          completed: today,
        },
        {
          name: 'Yesterday',
          completed: yesterday,
        },
        {
          name: 'last 7 days',
          completed: week,
        },
        {
          name: 'last 30 days',
          completed: month,
        },
      ];

      const series = datasets.map((d) =>
        Math.round((d.completed * 100) / 100, 0)
      );

      const options = getOptionChart(datasets);
      setClientChart({
        total,
        today,
        yesterday,
        week,
        month,
        series,
        options,
      });
    }
  }, [chartInfo]);

  //activities assigned
  useEffect(() => {
    if (get(chartInfo, 'assigned_activities')) {
      const total = get(chartInfo, 'assigned_activities.total', 0);
      const today = get(chartInfo, 'assigned_activities.today', 0);
      const yesterday = get(chartInfo, 'assigned_activities.yesterday', 0);
      const week = get(chartInfo, 'assigned_activities.week', 0);
      const month = get(chartInfo, 'assigned_activities.month', 0);

      const datasets = [
        {
          name: 'Today',
          completed: today,
        },
        {
          name: 'Yesterday',
          completed: yesterday,
        },
        {
          name: 'last 7 days',
          completed: week,
        },
        {
          name: 'last 30 days',
          completed: month,
        },
      ];
      const series = datasets.map((d) =>
        Math.round((d.completed * 100) / 100, 0)
      );
      const options = getOptionChart(datasets);
      setAssignedChart({
        total,
        today,
        yesterday,
        week,
        month,
        series,
        options,
      });
    }
  }, [chartInfo]);

  //completed activities
  useEffect(() => {
    if (get(chartInfo, 'completed_activities')) {
      const total = get(chartInfo, 'completed_activities.total', 0);
      const today = get(chartInfo, 'completed_activities.today', 0);
      const yesterday = get(chartInfo, 'completed_activities.yesterday', 0);
      const week = get(chartInfo, 'completed_activities.week', 0);
      const month = get(chartInfo, 'completed_activities.month', 0);

      const datasets = [
        {
          name: 'Today',
          completed: today,
        },
        {
          name: 'Yesterday',
          completed: yesterday,
        },
        {
          name: 'last 7 days',
          completed: week,
        },
        {
          name: 'last 30 days',
          completed: month,
        },
      ];
      const series = datasets.map((d) =>
        Math.round((d.completed * 100) / 100, 0)
      );
      const options = getOptionChart(datasets);
      setCompletedChart({
        total,
        today,
        yesterday,
        week,
        month,
        series,
        options,
      });
    }
  }, [chartInfo]);

  return (
    <div className="row">
      <ChartEngagement
        chartInfo={clientChart}
        title="Number of clients"
        total="Total Clients"
        prefix="Joined"
      />
      <ChartEngagement
        chartInfo={assignedChart}
        title="Number of activities assigned"
        total="Total released"
        prefix="Released"
      />
      <ChartEngagement
        chartInfo={completedChart}
        title="Number of activities completed"
        total="Total completed"
        prefix="Completed"
      />
    </div>
  );
}

export default EngagementTab;
