import React, { useState, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { Link, useParams } from 'react-router-dom';
import { DebounceTimeDefault } from '../../../../../utils/constants';
import { debounce } from 'lodash';
import ModalConfirm from '../../../../../components/Modal/ModalConfirm';

function SearchActivities({
  handleAssignActivity,
  handleRemoveActivity,
  clearSearchResult,
  searchActivity,
  searchResult,
}) {
  const { id } = useParams();

  const [keyword, setKeyword] = useState('');
  const [isOpenWarningModal, setOpenWarningModal] = useState(false);
  const [idRemoveAssignedActivity, setIdRemoveAssignedActivity] = useState();

  const handleOnSearch = (inputValue) => {
    const data = {
      clientId: id,
      queryParams: {
        title_containss: inputValue,
      },
    };
    searchActivity(data);
  };

  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  const handleOnFocus = (e) => {
    const data = {
      clientId: id,
      queryParams: {
        _limit: 4,
        _start: 0,
      },
    };

    searchActivity(data);
  };

  const onClearKeyWord = () => {
    setKeyword('');
    clearSearchResult();
  };

  const onHandleRemove = (assignedActivityId) => {
    setOpenWarningModal(true);
    setIdRemoveAssignedActivity(assignedActivityId);
  };

  const confirmDelete = () => {
    handleRemoveActivity(idRemoveAssignedActivity);
    setOpenWarningModal(false);
  };

  return (
    <>
      <div className="card mb-5 mb-xxl-8">
        <div
          className="card-body card-rounded p-0 d-flex justify-content-between"
          style={{ backgroundColor: '#F3F6F9' }}
        >
          <div className="d-flex flex-column flex-lg-row-auto py-10 py-md-14 px-10 px-md-20 pe-lg-0">
            <h1 className="fw-bolder text-dark mb-0">Assign Activities</h1>
            <div className="fs-3 mb-8">
              Search activities to assign to Client
            </div>
            <div
              className="mb-2 me-2"
              id="kt_docs_search_handler_basic"
              data-kt-search-keypress="true"
              data-kt-search-min-length={2}
              data-kt-search-enter="true"
              data-kt-search-layout="inline"
            >
              <form
                className="w-100 position-relative mb-5"
                data-kt-search-element="form"
                autoComplete="off"
                onSubmit={(e) => e.preventDefault()}
              >
                <input type="hidden" />
                <span className="svg-icon svg-icon-2 svg-icon-info svg-icon-lg-1 position-absolute top-50 ms-5 translate-middle-y">
                  <SVG src="/images/icons/search.svg" />
                </span>
                <input
                  className="form-control form-control-lg px-15 border-0"
                  type="text"
                  name="search"
                  placeholder="Search Activities"
                  data-kt-search-element="input"
                  value={keyword}
                  onChange={(e) => handleOnChange(e)}
                  onFocus={(e) => handleOnFocus(e)}
                />
                <span
                  className={`btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 ${
                    keyword === '' ? 'd-none' : ''
                  }`}
                  data-kt-search-element="clear"
                  onClick={onClearKeyWord}
                >
                  <i className="bi bi-x fs-2" />
                </span>
              </form>
              {/* Autocomplete */}
              {searchResult.length > 0 && (
                <div className="mt-n5 position-relative">
                  <div
                    className=" activity-search-result"
                    data-kt-search-element="results"
                  >
                    <div className="position-absolute p-5 bg-white z-index-2 activity-search-result__link-to-library border-bottom">
                      <Link
                        className="btn btn-primary w-100"
                        to="/admin/activity-library"
                      >
                        Go to Activity Library
                      </Link>
                    </div>
                    <div className="mh-300px scroll-y p-5 mt-20 position-absolute bg-white z-index-2 shadow-sm activity-search-result__wrapper">
                      {searchResult.map((item, index) => (
                        <div
                          className="d-flex align-items-center py-3 cursor-pointer mb-1"
                          data-kt-search-element="customer"
                          key={index}
                        >
                          <span className="fw-bold fs-6 text-gray-800 ellipsis-2rows pe-2">
                            {item.title}
                          </span>
                          {!item.assigned_activity_id && (
                            <button
                              className="btn btn-primary btn-sm ms-auto"
                              type="button"
                              onClick={() => handleAssignActivity(item.id)}
                            >
                              Assign
                            </button>
                          )}

                          {item.assigned_activity_id && (
                            <button
                              className="btn btn-primary btn-sm ms-auto"
                              type="button"
                              onClick={() =>
                                onHandleRemove(item.assigned_activity_id)
                              }
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* Autocomplete */}
            </div>
          </div>
          <div
            className="d-none d-md-flex flex-row-fluid mw-400px ml-auto bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
            style={{
              backgroundImage: 'url(/images/illustrations/copy.png)',
            }}
          ></div>
        </div>
      </div>
      <ModalConfirm
        isOpen={isOpenWarningModal}
        handleClose={() => setOpenWarningModal(false)}
        confirm={confirmDelete}
        modalTitle={'Are you sure you want to do this?'}
        modalNotes={'This action can not be undone'}
      />
    </>
  );
}

export default SearchActivities;
