import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientForm from './Components/ClientForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChartInfoByClientId,
  fetchLocation,
  fetchRole,
  fetchUsers,
  getUserById,
  resetActivitiesByClientId,
  resetChartInfoByClientId,
  resetUserDetail,
  selectLocations,
  selectRoles,
  selectUserDetail,
  selectUserList,
  updateUserById,
} from '../../../../../features/user';
import { UserType } from '../../../../utils/constants';
import { filterRole } from '../../../../utils/helper';
import { selectAuthUser } from '../../../../../features/user/auth';
import { get } from 'lodash';

function ClientDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUserDetail);
  const locations = useSelector(selectLocations);
  const roles = useSelector(selectRoles);
  const clinician = useSelector(selectUserList);
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    let locationQueryParams;
    let userQueryParams;
    if (get(authUser, 'role.id') === UserType.SupperAdmin) {
      userQueryParams = {
        role: UserType.Clinician,
        blocked: false,
      };
    } else {
      userQueryParams = {
        role: UserType.Clinician,
        clinician_location: get(authUser, 'clinician_location.id'),
        blocked: false,
      };
      locationQueryParams = {
        id: get(authUser, 'clinician_location.id'),
      };
    }

    dispatch(getUserById(id));
    dispatch(fetchLocation(locationQueryParams));
    dispatch(fetchRole());
    dispatch(fetchUsers(userQueryParams));
    dispatch(fetchChartInfoByClientId(id));

    return () => {
      dispatch(resetUserDetail());
      dispatch(resetActivitiesByClientId());
      dispatch(resetChartInfoByClientId());
    };
  }, []);

  const onHandleSubmit = (data) => {
    dispatch(updateUserById({ userData: data, id }));
  };

  return (
    <ClientForm
      userDetail={userDetail}
      onHandleSubmit={onHandleSubmit}
      locations={locations}
      roles={filterRole(roles, get(authUser, 'role.id', ''))}
      clinician={clinician}
    />
  );
}

export default ClientDetail;
