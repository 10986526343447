import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import User from '../../api/user';
import http from '../../services/http-common';
import { parseError } from '../../app/utils/helper';

export const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await User.login(data);
      http.setAuthorizationHeader(response.data.jwt);
      return response.data;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateMe = createAsyncThunk(
  'auth/updateMe',
  async ({ id, data, message = undefined }, { rejectWithValue }) => {
    try {
      const { data: user } = await User.updateUserById(data, id);
      return { user, message: message !== undefined ? message : 'Update account successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const updateMyPassword = createAsyncThunk(
  'auth/updateMyPassword',
  async (data, { rejectWithValue }) => {
    try {
      const { data: user } = await User.updatePassword(data);
      return { user, message: 'Update password successfully' };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data, { rejectWithValue }) => {
    try {
      const { data: user } = await User.forgotPassword(data);
      return user;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const { data: user } = await User.resetPassword(data);
      return user;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  }
);

const initialState = {
  accessToken: '',
  authUser: null,
  sentMail: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout() {},
    triggerSentMail: (state, action) => {
      state.sentMail = action.payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      const { jwt, user } = action.payload;
      state.accessToken = jwt;
      state.authUser = user;
    },

    [updateMe.fulfilled]: (state, action) => {
      const { user } = action.payload;
      state.authUser = user;
    },
  },
});

export const { logout, triggerSentMail } = authSlice.actions;
export const selectAuthUser = (state) => state.auth.authUser;
export const selectAcessToken = (state) => state.auth.accessToken;
export const selectSentMail = (state) => state.auth.sentMail;

export default authSlice.reducer;
