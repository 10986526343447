import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { UserType } from '../../../../../utils/constants';
import ActivitiesTab from './ActivitiesTab';
import DetailTab from './DetailTab';
import EngagementTab from './EngagementTab';

function ClientForm({
  onHandleSubmit,
  userDetail,
  locations,
  roles,
  clinician,
}) {
  const history = useHistory();

  const [tabActivate, setTabActivate] = useState(1);

  const onHandleCancel = () => {
    history.push(`/admin/user-list?userType=${UserType.Client}`);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="toolbar" id="kt_toolbar">
        <div className="container d-flex flex-stack flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
            <div
              className={`btn btn-active-accent fw-bolder ms-3 ${
                tabActivate === 1 ? 'active' : ''
              }`}
              onClick={() => setTabActivate(1)}
            >
              Activities
            </div>
            <div
              className={`btn btn-active-accent fw-bolder ms-3 ${
                tabActivate === 2 ? 'active' : ''
              }`}
              onClick={() => setTabActivate(2)}
            >
              Engagement
            </div>
            <div
              className={`btn btn-active-accent fw-bolder ms-3 ${
                tabActivate === 3 ? 'active' : ''
              }`}
              onClick={() => setTabActivate(3)}
            >
              Details
            </div>
          </div>
        </div>
      </div>
      <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              {tabActivate === 1 && <ActivitiesTab />}
              {tabActivate === 2 && <EngagementTab />}
              {tabActivate === 3 && (
                <DetailTab
                  onHandleSubmit={onHandleSubmit}
                  onHandleCancel={onHandleCancel}
                  userDetail={userDetail}
                  locations={locations}
                  roles={roles}
                  clinician={clinician}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientForm;
