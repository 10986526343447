import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { isMobile } from 'react-device-detect';
import {
  fetchPrivacyPolicies,
  selectPrivacyPolicies,
} from '../../../features/admin/privacyPolicy';

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const privacyPolicies = useSelector(selectPrivacyPolicies);

  useEffect(() => {
    dispatch(fetchPrivacyPolicies());
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div
            className={`d-flex flex-column-fluid d-flex justify-content-center mt-lg-0 ${
              isMobile ? '' : 'align-items-center'
            }`}
          >
            <div
              className="login-form login-forgot forgot-password-form"
              style={{ display: 'block', padding: '60px', height: '100%' }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: get(privacyPolicies, '[0].content', ''),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
