import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { displayIconSort, formatDateTime } from '../../../../../utils/helper';
import { get } from 'lodash';
import {
  previewAssignedActivity,
  selectActivitiesByClientId,
  selectPreviewActivity,
  selectTotalActivitiesByClientId,
} from '../../../../../../features/user';
import Pagination from '../../../../../components/Pagination';
import { UserType } from '../../../../../utils/constants';
import { useSortableData } from '../../../../../../hooks/useSortableData';
import ModalAssignedActivityDetail from './ModalAssignedActivityDetail';

function TableActivities({
  onPageClick,
  onChangeSize,
  onDeleteAssignedActivity,
}) {
  const dispatch = useDispatch();
  const { id: clientId } = useParams();

  const activities = useSelector(selectActivitiesByClientId);
  const totalActivity = useSelector(selectTotalActivitiesByClientId);
  const activityPreview = useSelector(selectPreviewActivity);

  const [selectedActivity, setSelectedActivity] = useState();
  const {
    items: dataTable,
    requestSort,
    sortConfig,
  } = useSortableData(activities);

  const getLinkAssignedBy = (activity) => {
    if (get(activity, 'assigned_by.role') === UserType.Admin) {
      return `/admin/admin-detail/${get(activity, 'assigned_by.id')}`;
    } else {
      return `/admin/clinician-detail/${get(activity, 'assigned_by.id')}`;
    }
  };

  const getNameAssignedBy = (activity) => {
    return `${get(activity, 'assigned_by.first_name', '')} ${get(
      activity,
      'assigned_by.surname',
      ''
    )}`;
  };

  const previewActivity = (id) => {
    dispatch(previewAssignedActivity({ clientId, id }));
  };

  return (
    <>
      <div className="card">
        <div className="card-header px-0 align-items-center">
          <h5>Assigned Activities</h5>
        </div>
        <div className="card-body p-0">
          <div
            id="kt_datatable_example_2_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="mobile-table ">
              <table
                className="table table-striped table-row-bordered gy-5 gs-7 align-middle"
                id="kt_datatable_example_2"
              >
                <thead>
                  <tr className="fw-bold fs-6 text-gray-800">
                    <th onClick={() => requestSort('activity.title')}>
                      Activities
                      {displayIconSort('activity.title', sortConfig)}
                    </th>
                    <th onClick={() => requestSort('activity.code')}>
                      Code
                      {displayIconSort('activity.code', sortConfig)}
                    </th>
                    <th
                      onClick={() =>
                        requestSort([
                          'assigned_by.first_name',
                          'assigned_by.surname',
                        ])
                      }
                    >
                      Assigned by
                      {displayIconSort(
                        ['assigned_by.first_name', 'assigned_by.surname'],
                        sortConfig
                      )}
                    </th>
                    <th onClick={() => requestSort('created_at')}>
                      Assigned at {displayIconSort('created_at', sortConfig)}
                    </th>
                    <th onClick={() => requestSort('completed_at')}>
                      Completed at
                      {displayIconSort('completed_at', sortConfig)}
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {dataTable &&
                    dataTable.length > 0 &&
                    dataTable.map((item, index) => (
                      <tr key={index}>
                        <td className="min-w-400px">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-75px symbol-2by3">
                              <img
                                src={get(
                                  item,
                                  'activity.activity_picture.formats.thumbnail.url'
                                )}
                              />
                            </div>
                            <div className="ms-5">
                              <Link
                                className="ellipsis-2rows fw-bolder mb-0"
                                to={
                                  get(item, 'activity.id', '')
                                    ? `/admin/activity-summary/${get(
                                        item,
                                        'activity.id',
                                        ''
                                      )}`
                                    : '#'
                                }
                              >
                                {get(item, 'activity.title', '')}
                              </Link>
                              {get(item, 'activity.topics') && (
                                <div className="mt-4">
                                  <div className="d-flex overflow-auto">
                                    {get(item, 'activity.topics').map(
                                      (topic, index) => (
                                        <Link
                                          key={index}
                                          className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
                                          to={`/admin/activity-library?topic=${topic.id}`}
                                        >
                                          {topic.name}
                                        </Link>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="min-w-100px">
                          {get(item, 'activity.code', '')}
                        </td>
                        <td className="min-w-150px">
                          <Link
                            className="underline"
                            to={getLinkAssignedBy(item)}
                          >
                            {getNameAssignedBy(item)}
                          </Link>
                        </td>
                        <td className="min-w-150px text-gray-500">
                          {formatDateTime(item.created_at)}
                        </td>
                        <td className="min-w-150px text-gray-500">
                          {formatDateTime(item.completed_at)}
                        </td>
                        <td className="min-w-125px pe-0 text-end">
                          <a
                            className="btn btn-icon btn-sm mx-3"
                            href="#modal-activity-preview"
                            data-bs-toggle="modal"
                            onClick={() => previewActivity(item.activity.id)}
                          >
                            <span className="svg-icon svg-icon-4 svg-icon-info">
                              <span className="svg-icon svg-icon-4  ">
                                <SVG src="/images/icons/preview.svg" />
                              </span>
                            </span>
                          </a>
                          <a
                            className="btn btn-icon btn-sm"
                            href="#modal-warning"
                            data-bs-toggle="modal"
                            onClick={() => setSelectedActivity(item.id)}
                          >
                            <span className="svg-icon svg-icon-4 svg-icon-info">
                              <span className="svg-icon svg-icon-4  ">
                                <SVG src="/images/icons/delete.svg" />
                              </span>
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {totalActivity > 0 && (
              <Pagination
                onChangeSize={onChangeSize}
                onPageClick={onPageClick}
                total={totalActivity}
              />
            )}
          </div>
        </div>
      </div>

      <ModalAssignedActivityDetail activityPreview={activityPreview.activity} />

      {/* Warning modal */}
      <div className="modal fade" id="modal-warning" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-exclamation-circle text-danger fs-5x" />
                </div>
                <h3>Are you sure you want to do this?</h3>
                <p>This action can not be undone</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="w-100px btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="w-100px btn btn-light-primary"
                type="button"
                data-bs-dismiss="modal"
                onClick={() => onDeleteAssignedActivity(selectedActivity)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableActivities;
