import axios from 'axios';
import React from 'react';
import SVG from 'react-inlinesvg';

function KnowledgeCard({
  srcImage,
  title,
  description,
  pdfFile,
  onPreviewPdf,
}) {
  return (
    <div className="col-md-4 col-xxl-4">
      <div className="card shadow-none">
        <div className="card-body p-0">
          <div className="overlay rounded overflow-hidden">
            <div className="overlay-wrapper rounded text-center">
              <div
                className="bgi-no-repeat bgi-size-cover rounded min-h-200px mw-350px mx-auto"
                style={{
                  backgroundImage: `url(${srcImage})`,
                  backgroundPosition: 'center',
                }}
              />
              {/* {srcImage && <img src={srcImage} alt="knowledge-image"/>} */}
            </div>
            <div className="overlay-layer bg-white bg-opacity-50 flex-column">
              <a
                className="btn fw-bolder btn-sm btn-info me-2 w-150px mb-3 py-5"
                href="#modal-pdf-preview"
                data-bs-toggle="modal"
                onClick={(e) => {
                  e.preventDefault();
                  onPreviewPdf(pdfFile);
                }}
              >
                Open PDF
                <span className="svg-icon svg-icon-3  opacity-50">
                  <SVG src="/images/icons/preview.svg" />
                </span>
              </a>
              <a
                className="btn fw-bolder btn-sm btn-primary me-2 w-150px py-5"
                href={pdfFile}
                target="_blank"
                download
              >
                Download PDF
                <span className="svg-icon svg-icon-3  opacity-50">
                  <SVG src="/images/icons/down.svg" />
                </span>
              </a>
            </div>
          </div>
          <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
            <span className="fs-4 fw-bolder text-gray-800 text-hover-primary mb-1 ellipsis-1row">
              {title}
            </span>
            <span className="fs-6 ellipsis-1row">{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeCard;
