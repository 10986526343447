import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import user from '../features/user';
import upload from '../features/upload';
import auth from '../features/user/auth';
import adminActivity from '../features/admin/activity';
import dailyQuotes from '../features/admin/dailyQuotes';
import faqs from '../features/admin/faqs';
import faqCategory from '../features/admin/faqCategory';
import termCondition from '../features/admin/termCondition';
import privacyPolicy from '../features/admin/privacyPolicy';
import knowledge from '../features/admin/knowledge';
import location from '../features/admin/location';
import adminAssignedActivity from '../features/admin/assignedActivity';
import clientActivity from '../features/client/activity';
import topic from '../features/admin/topic';
import loading from '../features/loading/loadingSlice';
import error from '../features/error/errorSlice';
import adminDashboard from '../features/admin/dashboard';
import clientDashboard from '../features/client/dashboard';
import clientKnowledge from '../features/client/knowledges';
import clientFAQ from '../features/client/faq';
import { history } from './utils/history';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const appReducer = combineReducers({
  user,
  auth,
  adminActivity,
  adminAssignedActivity,
  clientActivity,
  dailyQuotes,
  faqs,
  faqCategory,
  termCondition,
  privacyPolicy,
  knowledge,
  location,
  upload,
  topic,
  loading,
  error,
  adminDashboard,
  clientDashboard,
  clientKnowledge,
  clientFAQ,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    localStorage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(routerMiddleware(history)),
});
