import React from 'react';
import { Link } from 'react-router-dom';

function ClientFooter() {
  return (
    <footer
      className="footer mt-auto py-8 d-flex flex-lg-column"
      id="kt_footer"
    >
      <div className="container d-flex flex-column flex-center flex-lg-stack flex-md-row">
        <div className="mb-4 mb-lg-0">
          <Link to="/client/dashboard2">
            <img src="/images/logos/logo-footer.svg" alt="strong logo" />
          </Link>
        </div>
        <ul className="menu mb-4 mb-lg-0 fw-bold ms-lg-auto">
          <li className="menu-item">
            <Link to="/client/faq" className="menu-link text-hover-info">
              FAQ
            </Link>
          </li>
          <li className="menu-item">
            <Link
              className="menu-link text-hover-info"
              to="/term-condition"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </Link>
          </li>
          <li className="menu-item">
            <Link
              className="menu-link text-hover-info"
              to="/privacy-policy"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul className="menu mb-4 mb-lg-0 justify-content-center">
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://facebook.com/VeretisAus"
              target="_blank"
            >
              <i className="fab fa-lg text-hover-info fa-facebook-f"></i>
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://twitter.com/VeretisAus"
              target="_blank"
            >
              <i className="fab fa-lg text-hover-info fa-twitter"></i>
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://www.instagram.com/veretis_aus/"
              target="_blank"
            >
              <i className="fab fa-lg text-hover-info fa-instagram"></i>
            </a>
          </li>
          <li className="menu-item">
            <a
              className="menu-link"
              href="https://www.linkedin.com/company/veretis"
              target="_blank"
            >
              <i className="fab fa-lg text-hover-info fa-linkedin-in"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default ClientFooter;
