import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectOpenProfile } from '../features/user';
import UserLogin from './pages/UserLogin';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import Admin from './pages/Admin';
import Client from './pages/Client';
import DefaultThemeProvider from './themes/default/DefaultThemeProvider';
import AuthRoute from './routes/authRoute';
import ResetPassword from './pages/ResetPassword';
import Loading from './components/Loading';
import { TermCondition } from './pages/TermCondition';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

function App() {
  const isOpenProfile = useSelector(selectOpenProfile);
  return (
    <>
      <Loading />
      <DefaultThemeProvider>
        <Switch>
          <Route exact path="/" component={UserLogin} />
          <Route exact path="/login-confirmation" component={UserLogin} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/term-condition" component={TermCondition} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <AuthRoute path="/admin">
            <Admin />
          </AuthRoute>
          <AuthRoute path="/client">
            <Client />
          </AuthRoute>
          <Route path="*" component={NotFound} />
        </Switch>
      </DefaultThemeProvider>
      {isOpenProfile && (
        <div style={{ zIndex: 888 }} className="drawer-overlay" />
      )}
    </>
  );
}

export default App;
