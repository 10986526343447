import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ClinicianForm from './Components/ClinicianForm';
import {
  fetchChartInfoByClinicianId,
  fetchLocation,
  fetchRole,
  fetchUsers,
  getUserById,
  resetChartInfoByClinicianId,
  resetUserDetail,
  selectLocations,
  selectRoles,
  selectUserDetail,
  selectUserList,
  updateUserById,
} from '../../../../../features/user';
import { UserType } from '../../../../utils/constants';
import { filterRole } from '../../../../utils/helper';
import { get } from 'lodash';
import { selectAuthUser } from '../../../../../features/user/auth';

function ClinicianDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUserDetail);
  const locations = useSelector(selectLocations);
  const roles = useSelector(selectRoles);
  const clinician = useSelector(selectUserList);
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    let locationQueryParams;
    let userQueryParams;
    if (get(authUser, 'role.id') === UserType.SupperAdmin) {
      userQueryParams = {
        role: UserType.Clinician,
      };
    } else {
      userQueryParams = {
        role: UserType.Clinician,
        clinician_location: get(authUser, 'clinician_location.id'),
      };
      locationQueryParams = {
        id: get(authUser, 'clinician_location.id'),
      };
    }

    dispatch(getUserById(id));
    dispatch(fetchLocation(locationQueryParams));
    dispatch(fetchRole());
    dispatch(fetchUsers(userQueryParams));
    dispatch(fetchChartInfoByClinicianId(id));

    return () => {
      dispatch(resetUserDetail());
      dispatch(resetChartInfoByClinicianId());
    };
  }, []);

  const onHandleSubmit = (data) => {
    dispatch(updateUserById({ userData: data, id }));
  };

  // for case Clinician of yourself
  const filterClinician = (clinician) => {
    if (clinician) {
      return clinician.filter((item) => item.id != id);
    }
    return [];
  };

  return (
    <ClinicianForm
      userDetail={userDetail}
      onHandleSubmit={onHandleSubmit}
      locations={locations}
      roles={filterRole(roles, get(authUser, 'role.id', ''))}
      clinician={filterClinician(clinician)}
    />
  );
}

export default ClinicianDetail;
