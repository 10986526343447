import React from 'react';
import Chart from 'react-apexcharts';

function ChartEngagement({ chartInfo, title, total, prefix }) {
  return (
    <div className="col-sm-6 col-lg-12 col-xl-6">
      <div
        className="card mb-5 mb-xxl-8"
        style={{ backgroundColor: '#F3F6F9' }}
      >
        <div className="card-header align-items-center border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="fw-bolder text-dark fs-3">{title}</span>
            <span className="text-muted mt-2 fw-bold fs-6">
              {total}
              <strong className="text-gray-800 fw-bolder ps-3">
                {chartInfo.total}
              </strong>
            </span>
          </h3>
        </div>
        <div className="card-body card-rounded">
          <div
            className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center"
            style={{
              backgroundImage: 'url("/images/svg/illustrations/bg-2.svg")',
            }}
          >
            <Chart
              options={chartInfo.options}
              series={chartInfo.series}
              type="radialBar"
              height={265}
            />
          </div>
          <div className="row row-cols-2 pt-18">
            <div className="mb-4 px-5">
              <span className="fw-bolder text-gray-800">{chartInfo.today}</span>
              <span className="w-40px h-5px d-block rounded mt-1 bg-info"></span>
              <span>{`${prefix} today`}</span>
            </div>
            <div className="mb-4 px-5">
              <span className="fw-bolder text-gray-800">
                {chartInfo.yesterday}
              </span>
              <span className="w-40px h-5px d-block rounded mt-1 bg-success"></span>
              <span>{`${prefix} yesterday`}</span>
            </div>
            <div className="mb-4 px-5">
              <span className="fw-bolder text-gray-800">{chartInfo.week}</span>
              <span className="w-40px h-5px d-block rounded mt-1 bg-warning"></span>
              <span>{`${prefix} in last 7 days`}</span>
            </div>
            <div className="mb-4 px-5">
              <span className="fw-bolder text-gray-800">{chartInfo.month}</span>
              <span className="w-40px h-5px d-block rounded mt-1 bg-danger"></span>
              <span>{`${prefix} in last 31 days`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartEngagement;
