import React from 'react';
import SVG from 'react-inlinesvg';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../../../../features/user/auth';
import { UserType } from '../../../../utils/constants';

function Statistics({ totalAdminDashboard }) {
  const authUser = useSelector(selectAuthUser);
  return (
    <div className="row">
      <div
        className={`col-sm-6 ${
          get(authUser, 'role.id') !== UserType.Clinician
            ? 'col-xl-3'
            : 'col-xl-4'
        }`}
      >
        <div className="card card-stretch mb-5 mb-xxl-8 bg-danger">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px me-5">
                <span className="symbol-label bg-white bg-opacity-10">
                  <SVG src="/images/icons/dashboard.svg" />
                </span>
              </div>
              <div>
                <a className="fs-4 text-white text-hover-primary fw-bolder">
                  Number of Clients
                </a>
                <div className="fs-7 text-white opacity-75 fw-bold mt-1">
                  Total Clients: {get(totalAdminDashboard, 'totalClient', 0)}
                </div>
              </div>
            </div>
            <div className="fw-bold text-white pt-7">
              <div className="d-flex">
                <small>Joined today</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalClientJoined.today', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Joined yesterday</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalClientJoined.yesterday', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Joined in last 7 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalClientJoined.week', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Joined in last 31 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalClientJoined.month', 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {get(authUser, 'role.id') !== UserType.Clinician && (
        <div className="col-sm-6 col-xl-3">
          <div className="card card-stretch mb-5 mb-xxl-8 bg-success">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-5">
                  <span className="symbol-label bg-white bg-opacity-10">
                    <span className="symbol-label bg-white bg-opacity-10">
                      <SVG src="/images/icons/dashboard.svg" />
                    </span>
                  </span>
                </div>
                <div>
                  <a className="fs-4 text-white text-hover-primary fw-bolder">
                    Number of Clinician
                  </a>
                  <div className="fs-7 text-white opacity-75 fw-bold mt-1">
                    Total Clinician:{' '}
                    {get(totalAdminDashboard, 'totalClinician', 0)}
                  </div>
                </div>
              </div>
              <div className="fw-bold text-white pt-7">
                <div className="d-flex">
                  <small>Added today</small>
                  <span className="ms-auto fw-bolder fs-4">
                    {get(totalAdminDashboard, 'totalClinicianAdded.today', 0)}
                  </span>
                </div>
                <div className="d-flex  pt-2">
                  <small>Added yesterday</small>
                  <span className="ms-auto fw-bolder fs-4">
                    {get(
                      totalAdminDashboard,
                      'totalClinicianAdded.yesterday',
                      0
                    )}
                  </span>
                </div>
                <div className="d-flex  pt-2">
                  <small>Added in last 7 days</small>
                  <span className="ms-auto fw-bolder fs-4">
                    {get(totalAdminDashboard, 'totalClinicianAdded.week', 0)}
                  </span>
                </div>
                <div className="d-flex  pt-2">
                  <small>Added in last 31 days</small>
                  <span className="ms-auto fw-bolder fs-4">
                    {get(totalAdminDashboard, 'totalClinicianAdded.month', 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`col-sm-6 ${
          get(authUser, 'role.id') !== UserType.Clinician
            ? 'col-xl-3'
            : 'col-xl-4'
        }`}
      >
        <div className="card card-stretch mb-5 mb-xxl-8 bg-info">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px me-5">
                <span className="symbol-label bg-white bg-opacity-10">
                  <span className="symbol-label bg-white bg-opacity-10">
                    <SVG src="/images/icons/dashboard.svg" />
                  </span>
                </span>
              </div>
              <div>
                <a className="fs-4 text-white text-hover-primary fw-bolder">
                  Activities Completed
                </a>
                <div className="fs-7 text-white opacity-75 fw-bold mt-1">
                  Total Completed:{' '}
                  {get(totalAdminDashboard, 'totalCompletedActivity', 0)}
                </div>
              </div>
            </div>
            <div className="fw-bold text-white pt-7">
              <div className="d-flex">
                <small>Today</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalCompleted.today', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Yesterday</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalCompleted.yesterday', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Last 7 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalCompleted.week', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Last 31 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalCompleted.month', 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`col-sm-6 ${
          get(authUser, 'role.id') !== UserType.Clinician
            ? 'col-xl-3'
            : 'col-xl-4'
        }`}
      >
        <div className="card card-stretch mb-5 mb-xxl-8 bg-primary">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px me-5">
                <span className="symbol-label bg-white bg-opacity-10">
                  <span className="symbol-label bg-white bg-opacity-10">
                    <SVG src="/images/icons/dashboard.svg" />
                  </span>
                </span>
              </div>
              <div>
                <a className="fs-4 text-white text-hover-primary fw-bolder">
                  Activities Assigned
                </a>
                <div className="fs-7 text-white opacity-75 fw-bold mt-1">
                  Total Assigned:{' '}
                  {get(totalAdminDashboard, 'totalAssignedActivity', 0)}
                </div>
              </div>
            </div>
            <div className="fw-bold text-white pt-7">
              <div className="d-flex">
                <small>Today</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalAssigned.today', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Yesterday</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalAssigned.yesterday', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Last 7 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalAssigned.week', 0)}
                </span>
              </div>
              <div className="d-flex  pt-2">
                <small>Last 31 days</small>
                <span className="ms-auto fw-bolder fs-4">
                  {get(totalAdminDashboard, 'totalAssigned.month', 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
