import React, { useRef } from 'react';
import SVG from 'react-inlinesvg';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

function ModalAssignedActivityDetail({ activityPreview }) {
  const dismissBtn = useRef(null);
  const history = useHistory();

  const getChapter = (chapterIndex) => {
    if (chapterIndex < 10) {
      return `Chapter 0${chapterIndex + 1}`;
    } else {
      return `Chapter ${chapterIndex + 1}`;
    }
  };

  const getQuestion = (chapterIndex, questionChapter) => {
    if (chapterIndex < 10) {
      if (questionChapter < 10) {
        return `Chapter 0${chapterIndex + 1} / Question 0${
          questionChapter + 1
        }`;
      } else {
        return `Chapter 0${chapterIndex + 1} / Question ${questionChapter + 1}`;
      }
    } else {
      if (questionChapter < 10) {
        return `Chapter ${chapterIndex + 1} / Question 0${questionChapter + 1}`;
      } else {
        return `Chapter ${chapterIndex + 1} / Question ${questionChapter + 1}`;
      }
    }
  };

  const conditionRenderAnswer = (question) => {
    if (get(question, 'answer_type') === 'text') {
      if (get(question, 'first_answer.text_answer')) return true;
    } else {
      if (get(question, 'first_answer.answer.description')) return true;
    }
    return false;
  };

  const viewDetail = () => {
    if (dismissBtn.current) {
      dismissBtn.current.click();
    }
    history.push(`/admin/activity-summary/${get(activityPreview, 'id')}`);
  };

  return (
    <div className="modal fade" id="modal-activity-preview" tabIndex={-1}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Result</h3>
          </div>
          <div className="modal-body">
            <div className="d-flex bg-light-primary card-rounded flex-grow-1 mb-10">
              <div
                className="position-relative bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-end flex-grow-1"
                style={{
                  backgroundImage: `url(${get(
                    activityPreview,
                    'activity_picture.url'
                  )})`,
                }}
              ></div>
              <div className="py-10 px-7">
                <div className="w-200px w-lg-350px">
                  <h6 className="text-primary d-block fs-2 mb-5">
                    {get(activityPreview, 'title')}
                  </h6>
                  <p>{get(activityPreview, 'summary')}</p>
                </div>
                <div
                  className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6 float-end"
                  onClick={viewDetail}
                  to={`/admin/activity-summary/${get(activityPreview, 'id')}`}
                >
                  View details
                  <span className="svg-icon svg-icon-3  me-0">
                    <SVG src="/images/icons/right.svg" />
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-stretch">
              <div className="card-body" data-select2-id="select2-data-6-f3pf">
                {get(activityPreview, 'chapters', []).map(
                  (chapter, chapterIndex) => (
                    <div className="mb-10" key={chapterIndex}>
                      <h3 className="fw-bolder text-dark mb-5 fs-1">
                        {getChapter(chapterIndex)}
                      </h3>
                      <div className="fw-bold fs-4 text-muted">
                        <p>Chapter content</p>
                      </div>
                      <div className="rounded">
                        {get(chapter, 'asset_type') === 'Text' && (
                          <div className="card mb-5 bg-secondary">
                            <div className="card-body">
                              <p>{get(chapter, 'asset_url')}</p>
                            </div>
                          </div>
                        )}
                        {get(chapter, 'asset_type') === 'Video' && (
                          <div className="embed-responsive embed-responsive-16by9 mb-10 text-center">
                            <ReactPlayer
                              url={get(chapter, 'asset_url')}
                              className="w-100 h-300px h-md-400px embed-responsive-item"
                              controls={true}
                            />
                          </div>
                        )}
                        {get(chapter, 'asset_type') === 'Image' && (
                          <div className="embed-responsive embed-responsive-16by9 mb-10 text-center">
                            <img
                              className="w-100 h-300px h-md-400px embed-responsive-item"
                              src={get(chapter, 'asset_url')}
                              style={{ objectFit: 'cover' }}
                            />
                          </div>
                        )}
                      </div>
                      {get(chapter, 'questions', []).map(
                        (question, questionIndex) => (
                          <div className="mb-10" key={questionIndex}>
                            <h3 className="fw-bold fs-3 text-dark mb-5">
                              {getQuestion(chapterIndex, questionIndex)}
                            </h3>
                            <div className="mb-5">
                              <p className="fw-bold">Question</p>
                              <p className="text-gray-800">
                                {get(question, 'question')}
                              </p>
                            </div>
                            {conditionRenderAnswer(question) && (
                              <div className="mb-5">
                                <p className="fw-bold">First Answer</p>
                                <p className="text-gray-800"></p>
                                <div className="mb-10">
                                  <p>
                                    {get(question, 'answer_type') === 'text'
                                      ? get(
                                          question,
                                          'first_answer.text_answer'
                                        )
                                      : get(
                                          question,
                                          'first_answer.answer.description'
                                        )}
                                    <span className="svg-icon svg-icon-2 float-end">
                                      <i
                                        className={`bi ${
                                          get(
                                            question,
                                            'first_answer.is_correct'
                                          ) === true
                                            ? 'bi-check-lg'
                                            : 'bi-x-lg'
                                        } text-primary`}
                                      />
                                    </span>
                                  </p>
                                </div>
                                <p />
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                className="btn btn-light-primary"
                data-bs-dismiss="modal"
                ref={dismissBtn}
                style={{ display: 'none', opacity: 0 }}
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAssignedActivityDetail;
