import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../../utils/helper';
import './styles.scss';

const AssignedClientRow = ({
  activityId,
  assignedClient,
  previewActivity,
  getAssignedActivityId,
}) => {
  const getFullName = (user) => {
    return `${user?.first_name || ''} ${user?.surname || ''}`;
  };

  return (
    <>
      <tr key={activityId}>
        <td>
          <Link to={`/admin/client-detail/${assignedClient.assigned_user.id}`}>
            {getFullName(assignedClient?.assigned_user)}
          </Link>
        </td>
        <td>
          <Link to="#">{getFullName(assignedClient?.assigned_by)}</Link>
        </td>
        <td>{formatDateTime(assignedClient.created_at)}</td>
        <td>{formatDateTime(assignedClient.completed_at)}</td>
        <td className="w-100px text-end text-nowrap">
          <a
            className="btn btn-icon btn-bg-light btn-color-info btn-sm mx-3"
            onClick={previewActivity(assignedClient)}
          >
            <img
              src="/images/icons/eye.svg"
              alt="view-detail-eye"
              className="svg-icon svg-icon-4 icon-minimize"
            />
          </a>
          <a
            className="btn btn-icon btn-bg-light btn-color-info btn-sm"
            href="#modal-warning"
            data-bs-toggle="modal"
            onClick={getAssignedActivityId(assignedClient.id)}
          >
            <img
              src="/images/icons/trash.svg"
              alt="trash-icon"
              className="svg-icon svg-icon-4 svg-inline--fa fa-trash fa-w-14 icon-minimize"
            />
          </a>
        </td>
      </tr>
    </>
  );
};

export default AssignedClientRow;
