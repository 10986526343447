import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce, uniqBy, find } from 'lodash';

import { fetchActivities } from '../../../../../features/admin/activity';
import './Components/styles.scss';
import {
  fetchTopics,
  selectTopicList,
} from '../../../../../features/admin/topic';
import {
  DebounceTimeDefault,
  initialPagination,
} from '../../../../utils/constants';
import SearchActivity from './Components/SearchActivity';
import ActivityGrid from './Components/ActivityGrid';
import ActivityTable from './Components/ActivityTable';

const ShowType = {
  List: 0,
  Grid: 1,
};

const ActivityLibrary = ({ typeShow }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const topicId = parseInt(query.get('topic'));
  const dispatch = useDispatch();

  const topicList = useSelector(selectTopicList);

  // list activity
  const [activities, setActivities] = useState([]);
  const [latestActivities, setLatestActivities] = useState([]);
  const [totalActivity, setTotalActivity] = useState(0);

  // search, pagination
  const [valueTopic, setValueTopic] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [querySearch, setQuerySearch] = useState('');
  const [pagination, setPagination] = useState({
    ...initialPagination,
    _limit: 9,
  });

  //active memo
  const [isFocusSearch, setIsFocusSearch] = useState(false);

  useEffect(() => {
    const fetchLatestActivities = async () => {
      const result = await dispatch(
        fetchActivities({ _start: 0, _limit: 3, _sort: 'updated_at:desc' })
      ).unwrap();
      setLatestActivities(result.activities);
    };
    fetchLatestActivities();
    dispatch(fetchTopics());
    return () => {
      setActivities([]);
    };
  }, []);

  /* Search topic */
  useEffect(() => {
    if (topicList.length > 0) {
      const topic = find(
        topicList.map((topic) => ({ id: topic.id, value: topic.name })),
        { id: topicId }
      );
      if (topic) {
        setValueTopic([topic]);
      }
    }
  }, [topicId, topicList]);

  const onChangeTopic = (topic) => {
    setValueTopic(topic);
  };

  const onClickTopic = (e, topic) => {
    e.preventDefault();
    const topics = uniqBy([...valueTopic, topic], 'id');
    setValueTopic(topics);
  };
  /* Search topic */

  /* Search key word */
  const handleOnSearch = (inputValue) => {
    if (inputValue.length <= 2) return;
    setIsFocusSearch(false);
    setQuerySearch(inputValue);
  };

  const onSearch = useCallback(
    debounce(handleOnSearch, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value, valueTopic);
  };

  const onClearKeyWord = () => {
    setKeyword('');
    setQuerySearch('');
  };
  /* Search key word */

  const queryParams = useMemo(() => {
    let params = new URLSearchParams();
    if (querySearch) {
      params.append('title_containss', querySearch);
    }

    for (var i = 0; i < valueTopic.length; i++) {
      params.append('topics_in', valueTopic[i].id);
    }
    for (const key in pagination) {
      params.append(key, pagination[key]);
    }
    return params;
  }, [valueTopic, querySearch, pagination]);

  useEffect(() => {
    const searchActivity = async (params) => {
      const result = await dispatch(fetchActivities(params)).unwrap();
      if (typeShow === ShowType.Grid) {
        setActivities([...activities, ...result.activities]);
      } else {
        setActivities(result.activities);
        setTotalActivity(result.totalActivities);
      }
    };
    if (queryParams) {
      searchActivity(queryParams);
    }
  }, [queryParams]);

  // reset resutl and pagination when change keyword or topic
  useEffect(() => {
    setActivities([]);
    setPagination({
      ...initialPagination,
      _limit: typeShow === ShowType.Grid ? 9 : 10,
    });
  }, [valueTopic, querySearch, typeShow]);

  const handleLoadMore = () => {
    setPagination({
      ...pagination,
      _start: pagination._limit + pagination._start,
    });
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      _start: pagination._limit * page,
    });
  };

  const onChangeSize = (size) => {
    setPagination({ ...pagination, _start: 0, _limit: size });
  };

  const handleOnFocus = (e) => {
    setIsFocusSearch(true);
  };

  return (
    <>
      {/* Search activity */}
      <SearchActivity
        topicsMultiSelect={topicList.map((topic) => ({
          id: topic.id,
          value: topic.name,
        }))}
        keyword={keyword}
        handleOnChange={handleOnChange}
        handleOnFocus={handleOnFocus}
        onClearKeyWord={onClearKeyWord}
        onChangeTopic={onChangeTopic}
        valueTopic={valueTopic}
      />
      {typeShow === ShowType.Grid && (
        <ActivityGrid
          latestActivities={latestActivities}
          activities={activities}
          onClickTopic={onClickTopic}
          handleLoadMore={handleLoadMore}
          keywordSearching={keyword}
        />
      )}
      {typeShow === ShowType.List && (
        <ActivityTable
          activities={activities}
          onPageClick={onPageClick}
          onChangeSize={onChangeSize}
          totalActivity={totalActivity}
          onClickTopic={onClickTopic}
        />
      )}
    </>
  );
};

export default ActivityLibrary;
