import React from 'react';
import SVG from 'react-inlinesvg';
import { MultiSelect } from '../../../../../components/Selects';
import './styles.scss';

const SearchActivity = ({
  keyword,
  topicsMultiSelect,
  handleOnChange,
  onClearKeyWord,
  valueTopic,
  onChangeTopic,
  handleOnFocus,
}) => {
  return (
    <div className="card mb-5 mb-xxl-8">
      <div className="card-body">
        <div className="card">
          <div
            className="card-body card-rounded p-0 d-flex justify-content-between"
            style={{ backgroundColor: '#F3F6F9' }}
          >
            <div className="d-flex flex-column flex-lg-row-auto py-10 py-md-14 px-10 px-md-20 pe-lg-0 search-activity">
              <h1 className="fw-bolder text-dark mb-0">Search Activities</h1>
              <div className="fs-3 mb-8">
                Search activities to assign to Client
              </div>
              <div className="d-flex flex-column flex-xxl-row">
                <div className="mb-2 me-2" style={{ maxHeight: 46 }}>
                  <form
                    className="w-100 position-relative mb-5"
                    data-kt-search-element="form"
                    autoComplete="off"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input type="hidden" />
                    <span className="svg-icon svg-icon-2 svg-icon-info svg-icon-lg-1 position-absolute top-50 ms-5 translate-middle-y">
                      <SVG src="/images/icons/search.svg" />
                    </span>
                    <input
                      className="form-control form-control-lg px-15 border-0"
                      type="text"
                      name="search"
                      placeholder="Search Activities"
                      data-kt-search-element="input"
                      value={keyword}
                      onChange={(e) => handleOnChange(e)}
                      onFocus={(e) => handleOnFocus(e)}
                    />
                    <span
                      className={`btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 ${
                        keyword === '' ? 'd-none' : ''
                      }`}
                      data-kt-search-element="clear"
                      onClick={onClearKeyWord}
                    >
                      <i className="bi bi-x fs-2" />
                    </span>
                  </form>
                </div>
                <div
                  className="d-flex flex-center px-6 bg-white rounded mb-2 me-2 w-xxl-350px"
                  style={{ maxHeight: 46 }}
                >
                  <i className="bi bi-tag fs-3 text-info"></i>
                  <MultiSelect
                    data={topicsMultiSelect}
                    placeholder={'Select topic'}
                    getInputTags={(topic) => onChangeTopic(topic)}
                    noBorder
                    defaultValue={valueTopic}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-none d-md-flex flex-row-fluid mw-400px ml-auto bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
              style={{
                backgroundImage: 'url(/images/illustrations/copy.png)',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchActivity;
