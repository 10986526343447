import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import SVG from 'react-inlinesvg';

const ActivitySummaryHeader = ({ activity }) => {
  return (
    <>
      <div className="header bg-info bg-lg-transparent">
        <div className="container d-flex align-items-stretch justify-content-between">
          <div className="d-none d-lg-block">
            <div className="d-flex align-items-center">
              <h3 className="text-dark fw-bolder my-1 me-5">
                {get(activity, 'title')}
              </h3>
            </div>
            <ul className="breadcrumb breadcrumb-line bg-transparent text-muted fw-bold p-0 my-1 fs-7">
              <li className="breadcrumb-item">
                <Link
                  className="text-muted text-hover-primary"
                  to="/admin/dashboard"
                >
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  className="text-muted text-hover-primary"
                  to="/admin/activity-library"
                >
                  Activity Library
                </Link>
              </li>
              <li className="breadcrumb-item text-dark">
                {get(activity, 'title')}
              </li>
            </ul>
          </div>

          <div className="d-lg-none">
            <Link to="/admin/dashboard">
              <img
                className="h-30px logo"
                alt="Logo"
                src="/images/logos/logo-compact.svg"
              />
            </Link>
          </div>

          <div className="d-lg-none mt-2 mx-10 text-center flex-row-fluid">
            <h3 className="text-white fw-bolder my-1 ellipsis-1row mx-5">
              {get(activity, 'title')}
            </h3>
          </div>

          <div className="d-flex align-items-center ms-auto">
            <div className="d-none d-lg-block">
              <Link
                className="btn text-nowrap pe-0"
                to="/admin/activity-library"
              >
                <span className="svg-icon svg-icon-4 svg-icon-info me-2">
                  <img src="/images/icons/back.svg" alt="back-icon" />
                </span>
                Back
              </Link>
            </div>
          </div>

          <button
            className="btn btn-icon btn-info d-lg-none"
            id="kt_mega_menu_toggle"
            data-bs-toggle="modal"
            data-bs-target="#kt_mega_menu_modal"
          >
            <span className="svg-icon svg-icon-1  ">
              <SVG src="/images/icons/menu.svg" />
            </span>
          </button>
        </div>
      </div>

      <div
        className="bg-white shadow-sm d-lg-none py-2 px-4"
        data-kt-sticky="true"
        data-kt-sticky-name="docs-sticky-summary"
        data-kt-sticky-offset="{default: '200px'}"
        data-kt-sticky-width="100%"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="55px"
        data-kt-sticky-animation="true"
        data-kt-sticky-zindex={95}
        style={{ animationDuration: '0.3s' }}
        id="kt_back"
      >
        <div className="d-flex align-items-center overflow-auto">
          <div className="me-10 ms-n5">
            <Link className="btn text-nowrap pe-0" to="/admin/activity-library">
              <SVG src="/images/icons/back.svg" /> Back
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivitySummaryHeader;
