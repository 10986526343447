import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { debounce, get } from 'lodash';

import ActivitySummaryHeader from './ActivitySummaryHeader';
import AssignActivityForm from './AssignActivityForm';
import AssignedClientSearch from './AssignedClientSearch';
import AssignedClientList from './AssignedClientList';

import { selectAuthUser } from '../../../../../features/user/auth';
import {
  UserType,
  DebounceTimeDefault,
  initialPagination,
} from '../../../../utils/constants';
import { fetchUsers } from '../../../../../features/user';
import {
  fetchAssignedClients,
  createAssignedActivity,
  deleteAssignedActivity,
  fetchAssignedUserByName,
} from '../../../../../features/admin/assignedActivity';
import { fetchActivityById } from '../../../../../features/admin/activity';

const ActivitySummary = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activity, setActivity] = useState();
  const [userSelectedId, setUserSelectedId] = useState(null);
  const [deleteActivityId, setDeleteActivityId] = useState(null);
  const [usersAvailable, setUsersAvailable] = useState([]);
  const authUser = useSelector(selectAuthUser);
  const [assignedClients, setAssignedClient] = useState([]);
  const [pagination, setPagination] = useState({
    ...initialPagination,
    _limit: 50,
  });
  const [keyword, setKeyword] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const fetchAssignedUserQueryParams = useMemo(() => {
    return {
      activity: id,
      'assigned_user.first_name_containss': searchQuery,
      ...pagination,
    };
  }, [searchQuery, pagination, id]);

  useEffect(() => {
    const fetchAssignedUser = async () => {
      const assignedClients = await dispatch(
        fetchAssignedUserByName(fetchAssignedUserQueryParams)
      ).unwrap();
      setAssignedClient(assignedClients);
    };
    fetchAssignedUser();
  }, [fetchAssignedUserQueryParams]);

  useEffect(() => {
    let queryParams;
    // if user role is supper admin will load all users with blocked = false
    if (get(authUser, 'role.id') === UserType.SupperAdmin) {
      queryParams = {
        role: UserType.Client,
        blocked: false,
      };
      // if user role is admin will load all users with the same location with admin and blocked = false
    } else if (get(authUser, 'role.id') === UserType.Admin) {
      queryParams = {
        role: UserType.Client,
        blocked: false,
        clinician_location: get(authUser, 'clinician_location.id'),
      };
      // if user role is clinician will load all users who assigned to clinician and blocked = false
    } else if (get(authUser, 'role.id') === UserType.Clinician) {
      queryParams = {
        role: UserType.Client,
        blocked: false,
        clinician: get(authUser, 'id'),
      };
    }
    const fetchUsersAssigned = async () => {
      const assignedClients = await dispatch(fetchAssignedClients(id)).unwrap();
      setAssignedClient(assignedClients);
      const usersClient = await dispatch(fetchUsers(queryParams)).unwrap();
      if (usersClient?.users?.length > 0 && assignedClients?.length > 0) {
        const assignedClientIds = assignedClients.map(
          (assign) => assign.assigned_user.id
        );
        const usersAvailable = usersClient.users.filter(
          (user) => !assignedClientIds.includes(user.id)
        );
        return setUsersAvailable(usersAvailable);
      }
      return setUsersAvailable(usersClient?.users);
    };
    fetchUsersAssigned();
    getActivityDetail();
  }, [userSelectedId, deleteActivityId]);

  const handleAssignClient = async (assignedUser) => {
    if (!activity || !assignedUser.key || !authUser) return;
    await dispatch(
      createAssignedActivity({
        assigned_user: assignedUser.key,
        assigned_by: authUser.id,
        activity: activity.id,
      })
    );
    return setUserSelectedId(assignedUser.key);
  };

  const getActivityDetail = async () => {
    try {
      const activityResult = await dispatch(fetchActivityById(id)).unwrap();
      setActivity(activityResult);
    } catch (error) {}
  };

  const onDeleteAssignedActivity = async (selectedActivity) => {
    await dispatch(deleteAssignedActivity(selectedActivity));

    return setDeleteActivityId(selectedActivity);
  };

  const handleSearching = (inputValue) => {
    setSearchQuery(inputValue);
  };

  const onSearch = useCallback(
    debounce(handleSearching, DebounceTimeDefault),
    []
  );

  const handleOnChange = (e) => {
    setKeyword(e.target.value);
    onSearch(e.target.value);
  };

  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      _start: pagination._limit * page,
    });
  };

  const onChangeSize = (size) => {
    setPagination({ ...pagination, _start: 0, _limit: size });
  };

  return (
    <>
      <ActivitySummaryHeader activity={activity} />
      <div className="d-flex flex-column flex-column-fluid">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container">
            <div className="d-flex flex-column flex-md-row">
              <div className="flex-column flex-md-row-auto w-100 w-md-300px w-xxl-350px order-md-2">
                <AssignActivityForm
                  activity={activity}
                  activityId={id}
                  usersAvailable={usersAvailable}
                  handleAssignClient={handleAssignClient}
                  assignedClients={assignedClients}
                  deleteActivityId={deleteActivityId}
                />
              </div>
              <div className="flex-md-row-fluid me-md-12 order-md-1">
                <div className="card">
                  <AssignedClientSearch
                    searchingUser={handleOnChange}
                    keyword={keyword}
                  />
                  <div className="card-body pt-0 pb-10">
                    <AssignedClientList
                      assignedClients={assignedClients}
                      onDeleteAssignedActivity={onDeleteAssignedActivity}
                      onPageClick={onPageClick}
                      onChangeSize={onChangeSize}
                      pagination={pagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivitySummary;
