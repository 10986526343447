import React, { useEffect, useState, useRef } from 'react';
import { uniqBy } from 'lodash';
import './styles.scss';

const MultiSelect = ({
  defaultValue,
  data,
  getInputTags,
  noBorder,
  placeholder,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [listSelected, setListSelected] = useState([]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      setListSelected(defaultValue);
    }
  }, [defaultValue]);

  const handleSelect = (selectedId) => {
    const itemSelected =
      data.length > 0 && data.filter((e) => e.id === selectedId);
    if (
      listSelected.length > 0 &&
      listSelected.filter((e) => e.id === selectedId).length > 0
    ) {
      const listSelectedAdjust = listSelected.filter(
        (e) => e.id !== selectedId
      );
      setListSelected(uniqBy(listSelectedAdjust, 'id'));
      getInputTags(listSelectedAdjust);
      return setIsOpen(false);
    }
    setListSelected(uniqBy([...listSelected, ...itemSelected], 'id'));
    getInputTags([...listSelected, ...itemSelected]);
    return setIsOpen(false);
  };

  const handleRemove = (selectedId) => {
    const listSelectedAdjust = listSelected.filter((e) => e.id !== selectedId);
    setListSelected(uniqBy(listSelectedAdjust, 'id'));
    getInputTags(listSelectedAdjust);
    return setIsOpen(false);
  };

  return (
    <button
      className={`form-control position-relative ${noBorder && 'border-0'}`}
      onClick={() => setIsOpen(!isOpen)}
      disabled={isDisabled}
    >
      <div className="select-input select-input--multiple">
        <div className="selected-list">
          {listSelected.length > 0 ? (
            listSelected.map((item, index) =>
              isDisabled === false ? (
                <div
                  className="selected-item"
                  key={index}
                  onClick={() => handleRemove(item.id)}
                >
                  <span aria-hidden="true">×</span>
                  <span>{item.value}</span>
                </div>
              ) : (
                <span key={index}>{item.value} &nbsp; &nbsp;</span>
              )
            )
          ) : (
            <span>{placeholder}</span>
          )}
          <div className="select-click" />
        </div>
      </div>

      {isOpen && isDisabled === false ? (
        <div className="select-list position-select-list">
          {data.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelect(item.id)}
              className="select-item"
            >
              <span className="select-title">{item.value}</span>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </button>
  );
};

const SingleSelect = ({
  isResetSelected,
  getData,
  placeholder,
  data,
  defaultValue,
  isDisabled,
  customClassName,
  customPositionSelectbox,
  hasAssignBtn,
  searchKeyword,
  searchingUser,
  hasSearchUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue?.id]);

  useEffect(() => {
    return () => {
      setSelected(null);
      setIsOpen(false);
    };
  }, [isResetSelected]);

  const checkOpenSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (item) => () => {
    setSelected(item);
    getData(item);
    setIsFocus(false);
    return setIsOpen(false);
  };

  return (
    <div
      className={`form-control position-relative ${customClassName}`}
      style={{ minWidth: 160 }}
      disabled={isDisabled}
    >
      <div
        className="select-input select-input--multiple"
        onClick={checkOpenSelect}
      >
        <div className="selected-list">
          {selected?.title ? (
            <span>{selected.title}</span>
          ) : (
            <span>{placeholder}</span>
          )}
          <div className="select-click" />
        </div>
      </div>

      {isOpen ? (
        <div
          className={`select-list position-select-list ${customPositionSelectbox}`}
        >
          {hasSearchUser && (
            <div className="sticky-search">
              <div className="search-wrap">
                <input
                  type="text"
                  placeholder=""
                  value={searchKeyword}
                  onChange={searchingUser}
                  className="select-search"
                />
              </div>
              <div className="select-header-title">
                <span>Select client to assign this activity</span>
              </div>
            </div>
          )}
          {data.map((item, index) => (
            <div
              key={index}
              onClick={handleSelect(item)}
              className="select-item"
              type="button"
            >
              <span className="select-title">{item.title}</span>
              {hasAssignBtn && (
                <div className="btn btn-primary btn-sm ms-auto">Assign</div>
              )}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export { MultiSelect, SingleSelect };
