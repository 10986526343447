export const LOGIN_API = 'login';
export const DefaultErrorMsg = 'Something went wrong, please contact admin';

export const themeColors = {
  primary: '#41b883',
  primaryMedium: '#b4e4ce',
  primaryLight: '#f7fcfa',
  secondary: '#ff227d',
  accent: '#797bf2',
  accentMedium: '#d4b3ff',
  accentLight: '#b8ccff',
  success: '#06d6a0',
  info: '#039BE5',
  warning: '#faae42',
  danger: '#FF7273',
  purple: '#8269B2',
  blue: '#37C3FF',
  green: '#93E088',
  yellow: '#FFD66E',
  orange: '#FFA981',
  lightText: '#a2a5b9',
  fadeGrey: '#ededed',
};

export const UserType = {
  SupperAdmin: 3,
  Admin: 4,
  Clinician: 5,
  Client: 6,
};

export const DebounceTimeDefault = 500;

export const defaultChartValue = {
  total: 0,
  today: 0,
  yesterday: 0,
  week: 0,
  month: 0,
  series: [0, 0, 0, 0],
  options: {},
};

export const SizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];
export const DefaultSorted = [{ dataField: 'id', order: 'asc' }];
export const initialPagination = {
  _start: 0,
  _limit: SizePerPageList[0].value,
  _sort: 'created_at:desc',
};

//^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{6,}$/ -> regex complex capital and special character.
export const PasswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{6,}$/;
