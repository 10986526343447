import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { isFulfilledAction, isRejectedAction } from '../../app/utils/helper';
import { DefaultErrorMsg } from '../../app/utils/constants';
import useNotyf from '../../hooks/useNotyf';

const notif = useNotyf();

const features = [
  'user/',
  'upload/',
  'auth/',
  'admin/',
  'adminActivity/',
  'adminAssignedActivity/',
  'clientActivity/',
  'adminDashboard/',
  'clientDashboard/',
  'clientKnowledges/',
  'clientFAQ/',
];

const initialState = {
  isUnauthorized: false,
  messageUnauthorized: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetUnauthorized: (state, action) => {
      state.isUnauthorized = false;
      state.messageUnauthorized = '';
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isFulfilledAction(features), (state, action) => {
      if (get(action.payload, 'message', '') !== '') {
        notif.success(get(action.payload, 'message'));
      }
    });
    builder.addMatcher(isRejectedAction(features), (state, action) => {
      if (get(action.payload, 'statusCode') === 401) {
        state.isUnauthorized = true;
        state.messageUnauthorized = get(
          action.payload,
          'message',
          DefaultErrorMsg
        );
      } else {
        notif.error(get(action.payload, 'message', DefaultErrorMsg));
      }
    });
  },
});

export const { resetUnauthorized } = errorSlice.actions;

export const selectStateUnauthorized = (state) => state.error;

export default errorSlice.reducer;
