import React from 'react';
import { Link, useLocation, matchPath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import SVG from 'react-inlinesvg';
import { chain } from 'lodash';

import LinkBack from './BackButton';
import Breadcrumbs from './Breadcrumbs';
import { logout, selectAuthUser } from '../../features/user/auth';
import { toggleOpenProfile } from '../../features/user/index';

import './componentStyle.scss';

const menuMobile = [
  {
    title: 'Dashboard',
    subTitle: 'Visit your dashboard',
    url: '/client/dashboard2',
    icon: '/images/illustrations/menu-item-1.svg',
  },
  {
    title: 'Activities',
    subTitle: 'Check out your activities',
    url: '/client/activity',
    icon: '/images/illustrations/menu-item-2.svg',
  },
  {
    title: 'Knowledge Base',
    subTitle: 'Find more resources',
    url: '/client/knowledge-hub',
    icon: '/images/illustrations/menu-item-3.svg',
  },
  {
    title: 'FAQ',
    subTitle: 'Read through our FAQs',
    url: '/client/faq',
    icon: '/images/illustrations/menu-item-4.svg',
  },
];

function HeaderClient({ routers, isScrollAnimated }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectAuthUser);
  const path = location.pathname;
  const page = chain(routers)
    .filter(
      (router) =>
        matchPath(path, { path: router.path, exact: true, strict: true }) !==
        null
    )
    .last()
    .value();

  const onHanleSignOut = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const setOpenProfileTab = () => {
    dispatch(toggleOpenProfile(true));
  };

  const avatar = get(userInfo, 'profile_picture.0.url', '');
  const firstName = get(userInfo, 'first_name', '');
  return (
    <>
      {/* Menu Mobile*/}
      <div
        className="modal bg-white fade"
        id="kt_mega_menu_modal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content shadow-none">
            <div className="container">
              <div className="modal-header d-flex flex-stack border-0">
                <div className="d-flex align-items-center">
                  <Link to="/client/dashboard2">
                    <img
                      className="h-30px logo"
                      alt="Logo"
                      src="/images/logos/logo-mobile-client.svg"
                    />
                  </Link>
                </div>
                <div
                  className="btn btn-icon btn-sm btn-light-info ms-2"
                  data-bs-dismiss="modal"
                >
                  <span className="svg-icon svg-icon-1  ">
                    <SVG src="/images/icons/close.svg" />
                  </span>
                </div>
              </div>
              <div className="modal-body">
                <div className="mb-10">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-60px symbol-rounded bg-light-info mt-1 me-5">
                      {avatar && <img alt="avatar-url" src={avatar} />}
                    </div>
                    <div className="d-flex flex-column">
                      <span className="card-label fw-bolder text-dark fs-2">
                        Hey, {firstName}
                      </span>
                      <p
                        className="underline text-gray-500 cursor-pointer"
                        onClick={setOpenProfileTab}
                        data-bs-dismiss="modal"
                      >
                        My account
                      </p>
                    </div>
                  </div>
                  <div className="mt-10 mb-4">
                    <a
                      className="btn btn-info w-100 fw-bolder d-flex flex-center py-5"
                      href="https://veretis-group.au2.cliniko.com/bookings?appointment_type_id[]=569775020276980285&appointment_type_id[]=569775335982242367#service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book an appointment
                      <i className="far fa-calendar ms-3 text-gray-500 fs-4" />
                    </a>
                  </div>
                </div>
                {menuMobile.map((menu, index) => (
                  <Link
                    key={index}
                    className="card mb-4 hoverable bg-light-info"
                    to={menu.url}
                  >
                    <div
                      className="card-body px-5 py-0"
                      data-bs-dismiss="modal"
                    >
                      <div className="d-flex align-items-center">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-dark fs-2">
                            {menu.title}
                          </span>
                          <span className="text-muted mt-2 fw-bold fs-5 ellipsis-1row">
                            {menu.subTitle}
                          </span>
                        </h3>
                        <figure className="ms-auto mb-0 mt-1">
                          <img
                            className="img-fluid"
                            src={menu.icon}
                            alt={menu.title}
                          />
                        </figure>
                      </div>
                    </div>
                  </Link>
                ))}
                <div className="text-center mt-10">
                  <a
                    className="btn btn-link text-info text-hover-primary"
                    onClick={onHanleSignOut}
                    data-bs-dismiss="modal"
                  >
                    Sign out
                    <span className="svg-icon svg-icon-3 me-0 ms-2 opacity-50">
                      <SVG src="/images/icons/sign-out.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {page.displayHeader === true ? (
        <div
          className={`header header-mg d-flex justify-content-center sticky resize-header ${
            isScrollAnimated ? 'bg-header' : 'bg-info bg-lg-transparent'
          }`}
          style={{
            animationDuration: '0.3s',
            width: '100%',
            position: isScrollAnimated && 'sticky',
            padding: '30px 0px',
          }}
        >
          <div
            className={`container d-flex align-items-stretch justify-content-between padding-header-desktop`}
          >
            <div className="d-none d-lg-block">
              {page?.title && (
                <div className="d-flex align-items-center">
                  <h3 className="text-dark fw-bolder my-1 me-5">
                    {page?.title}
                  </h3>
                </div>
              )}

              {page?.breadCrumbs?.length > 0 && (
                <Breadcrumbs list={page.breadCrumbs} />
              )}
            </div>

            <div className="d-lg-none">
              <Link to="/client/dashboard2">
                <img
                  className="h-30px logo"
                  alt="Logo"
                  src="/images/logos/logo-compact.svg"
                />
              </Link>
            </div>

            {page?.title && (
              <div className="d-lg-none mt-2 mx-10 text-center flex-row-fluid">
                <h3 className="text-white fw-bolder my-1 ellipsis-1row mx-5">
                  {page?.title}
                </h3>
              </div>
            )}

            <div className="d-flex align-items-center ms-auto">
              {page?.isShowUser && (
                <button
                  className="btn btn-icon btn-icon-user me-3 d-none d-lg-block"
                  id="kt_drawer_chat_toggle"
                  onClick={() => dispatch(toggleOpenProfile(true))}
                >
                  <i className="far fa-user-circle fs-3" />
                </button>
              )}

              {page?.needBack && (
                <div className="d-none d-lg-block">
                  <LinkBack />
                </div>
              )}

              <button
                className="btn btn-icon btn-info d-lg-none"
                id="kt_mega_menu_toggle"
                data-bs-toggle="modal"
                data-bs-target="#kt_mega_menu_modal"
              >
                <span className="svg-icon svg-icon-1  ">
                  <SVG src="/images/icons/menu.svg" />
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* back on mobile    */}
      {get(page, 'needBackMobile') && (
        <div
          className="bg-white shadow-sm d-lg-none py-2 px-4"
          data-kt-sticky="true"
          data-kt-sticky-name="docs-sticky-summary"
          data-kt-sticky-offset="{default: '200px'}"
          data-kt-sticky-width="100%"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="55px"
          data-kt-sticky-animation="true"
          data-kt-sticky-zindex={95}
          style={{ animationDuration: '0.3s' }}
          id="kt_back"
        >
          <div className="d-flex align-items-center overflow-auto">
            <div className="me-10 ms-n5">
              <a
                className="btn text-nowrap pe-0"
                onClick={() => history.goBack()}
              >
                <span className="svg-icon svg-icon-4 svg-icon-info me-2">
                  <SVG src="/images/icons/back.svg" />
                </span>
                Back
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderClient;
