import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

function ActivityCard({ activity, onClickTopic }) {
  return (
    <div className="card card-stretch">
      <div className="card-body p-0 border-0 ribbon ribbon-start ribbon-clip">
        <Link className="d-block" to={`/admin/activity-summary/${activity.id}`}>
          <div
            className="bgi-no-repeat bgi-size-cover rounded-top min-h-200px"
            style={{
              backgroundImage: `url(${
                get(activity, 'activity_picture.url') ||
                '/images/illustrations/01.png'
              })`,
              backgroundPosition: 'center',
            }}
          ></div>
        </Link>
        <div className="ribbon-label ribbon-topic">
          {activity.code}
          <span className="ribbon-inner bg-warning"></span>
        </div>
      </div>
      <div className="card-body px-5">
        <h4 className="fw-bold">
          <Link
            className="ellipsis-2rows text-dark"
            to={`/admin/activity-summary/${activity.id}`}
          >
            {activity.title}
          </Link>
        </h4>
        <p className="description text-gray-500 fs-14 py-4 activity-summary-height">
          {activity.summary}
        </p>
        <div className="d-flex overflow-auto">
          {get(activity, 'topics', []).map((topic, index) => (
            <Link
              to="#"
              key={`topic-${index}`}
              className="badge badge-pill badge-light-info me-3 mb-1 fw-normal"
              onClick={(e) => {
                onClickTopic(e, {
                  id: topic.id,
                  value: topic.name,
                });
              }}
            >
              {topic.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="p-5 d-flex flex-stack text-gray-500">
        <div className="border-top w-100 text-end mt-n8">
          <button
            className="btn btn-link btn-sm me-5 ms-auto"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Assigned"
          >
            <p style={{ marginBottom: 0 }}>
              <img
                src="/images/icons/people-group.svg"
                alt="group-people"
                style={{ width: 18, height: 18, marginRight: 7 }}
              />
              <span className="fs-bold text-gray-600">
                {activity.countAssigned}
              </span>
            </p>
          </button>
          <button
            className="btn btn-link btn-sm"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Completed"
          >
            <p style={{ marginBottom: 0 }}>
              <img
                src="/images/icons/paper-check.svg"
                alt="paper-check"
                style={{ width: 18, height: 18, marginRight: 7 }}
              />
              <span className="fs-bold text-gray-600">
                {activity.countCompleted}
              </span>
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ActivityCard;
