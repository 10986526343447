import React from 'react';

const AssignedClientSearch = ({ searchingUser, keyword }) => {
  return (
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bolder text-dark fs-2">
          Assigned Clients
        </span>
      </h3>
      <div className="card-toolbar">
        <div className="d-flex align-items-center position-relative my-1 ms-auto">
          <span className="svg-icon svg-icon-2 svg-icon-info ms-6 z-index-1">
            <img src="/images/icons/search.svg" alt="search-icon" />
          </span>
          <input
            className="form-control form-control-solid bg-white w-100 mw-350px ps-15 ms-n12"
            type="text"
            placeholder="Search (client name)"
            value={keyword}
            onChange={searchingUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignedClientSearch;
