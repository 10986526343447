import React from 'react';
import { Link, useLocation, matchPath, useHistory } from 'react-router-dom';
import { chain } from 'lodash';
import SVG from 'react-inlinesvg';
import LinkBack from './BackButton';
import Breadcrumbs from './Breadcrumbs';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectAuthUser } from '../../features/user/auth';

const Header = ({ routers, isScrollAnimated }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector(selectAuthUser);
  const role = get(authUser, 'role.type', 'admin');

  const path = location.pathname;
  const page = chain(routers)
    .filter(
      (router) =>
        matchPath(path, { path: router.path, exact: true, strict: true }) !==
        null
    )
    .last()
    .value();

  const menuMobile = [
    {
      title: 'Dashboard',
      url: '/admin/dashboard',
      icon: '/images/illustrations/menu-item-1.svg',
      roles: ['admin', 'super_admin', 'clinician'],
    },
    {
      title: 'User List',
      url: '/admin/user-list',
      icon: '/images/illustrations/menu-item-4.svg',
      roles: ['admin', 'super_admin', 'clinician'],
    },
    {
      title: 'Activity Library',
      url: '/admin/activity-library',
      icon: '/images/illustrations/menu-item-2.svg',
      roles: ['admin', 'super_admin', 'clinician'],
    },
    {
      title: 'CMS',
      url: '/admin/cms',
      icon: '/images/illustrations/menu-item-3.svg',
      roles: ['super_admin'],
    },
    {
      title: 'My Account',
      url: '/admin/account',
      icon: '/images/illustrations/menu-item-5.svg',
      roles: ['admin', 'super_admin', 'clinician'],
    },
  ];

  const onHanleSignOut = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <>
      {get(page, 'isHideHeader') ? null : (
        <div
          id="kt_header"
          className={`header bg-info bg-lg-transparent`}
          data-kt-sticky="true"
          data-kt-sticky-name="header"
          data-kt-sticky-offset="{default: '200px', lg: '300px'}"
          style={{ animationDuration: '0.3s', width: '99%' }}
        >
          <div className="container d-flex align-items-stretch justify-content-between">
            <div className="d-none d-lg-block">
              <div className="d-flex align-items-center">
                <h3 className="text-dark fw-bolder my-1 me-5">
                  {get(page, 'name')}
                </h3>
              </div>

              {get(page, 'breadCrumbs.length') > 0 && (
                <Breadcrumbs list={page.breadCrumbs} />
              )}
            </div>

            <div className="d-lg-none">
              <Link to="/admin/dashboard">
                <img
                  className="h-30px logo"
                  alt="Logo"
                  src="/images/logos/logo-compact.svg"
                />
              </Link>
            </div>

            <div className="d-lg-none mt-2 mx-10 text-center flex-row-fluid">
              <h3 className="text-white fw-bolder my-1 ellipsis-1row mx-5">
                {get(page, 'name')}
              </h3>
            </div>

            <div className="d-flex align-items-center ms-auto">
              {get(page, 'isShowUser') && (
                <button
                  id="kt_drawer_chat_toggle"
                  className="btn btn-icon btn-icon-user me-3 d-none d-lg-block"
                  onClick={() => history.push('/admin/account')}
                >
                  <i className="far fa-user-circle fs-3"></i>
                </button>
              )}

              {get(page, 'needBack') && (
                <div className="d-none d-lg-block">
                  <LinkBack />
                </div>
              )}

              <button
                id="kt_drawer_chat_toggle"
                className={`btn btn-icon btn-info d-lg-none`}
                data-bs-toggle="modal"
                data-bs-target="#kt_mega_menu_modal"
              >
                <span className="svg-icon svg-icon-1  ">
                  <SVG src="/images/icons/menu.svg" />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* back on mobile    */}
      {get(page, 'needBack') && (
        <div
          className="bg-white shadow-sm d-lg-none py-2 px-4"
          data-kt-sticky="true"
          data-kt-sticky-name="docs-sticky-summary"
          data-kt-sticky-offset="{default: '200px'}"
          data-kt-sticky-width="100%"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="55px"
          data-kt-sticky-animation="true"
          data-kt-sticky-zindex={95}
          style={{ animationDuration: '0.3s' }}
          id="kt_back"
        >
          <div className="d-flex align-items-center overflow-auto">
            <div className="me-10 ms-n5">
              <a
                className="btn text-nowrap pe-0"
                onClick={() => history.goBack()}
              >
                <span className="svg-icon svg-icon-4 svg-icon-info me-2">
                  <SVG src="/images/icons/back.svg" />
                </span>
                Back
              </a>
            </div>
          </div>
        </div>
      )}

      {/* Menu Mobile   */}
      <div
        className="modal bg-white fade"
        id="kt_mega_menu_modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content shadow-none">
            <div className="container">
              <div className="modal-header d-flex flex-stack border-0">
                <div className="d-flex align-items-center">
                  <Link to="/admin/dashboard">
                    <img
                      className="h-30px logo"
                      alt="Logo"
                      src="/images/logos/logo-mobile-client.svg"
                    />
                  </Link>
                </div>
                <div
                  className="btn btn-icon btn-sm btn-light-info ms-2"
                  data-bs-dismiss="modal"
                >
                  <span className="svg-icon svg-icon-1  ">
                    <SVG src="/images/icons/close.svg" />
                  </span>
                </div>
              </div>
              <div className="modal-body">
                {/* {menuMobile.map((item, index) => (
                  <Link
                    key={index}
                    className="card mb-4 hoverable bg-undefined"
                    to={item.url}
                  >
                    <div
                      className="card-body px-5 py-0"
                      data-bs-dismiss="modal"
                    >
                      <div className="d-flex align-items-center">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-dark fs-2">
                            {item.title}
                          </span>
                        </h3>
                        <div
                          className="ms-auto mb-0 mt-1 btn btn-icon btn-sm btn-light-info"
                          data-bs-dismiss="modal"
                        >
                          <span className="svg-icon svg-icon-1">
                            <SVG src={item.icon} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))} */}

                {menuMobile.map((menu, index) => {
                  return (
                    menu.roles.includes(role) && (
                      <Link
                        key={index}
                        className="card mb-4 hoverable bg-light-info"
                        to={menu.url}
                      >
                        <div
                          className="card-body px-5 py-0"
                          data-bs-dismiss="modal"
                        >
                          <div className="d-flex align-items-center">
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bolder text-dark fs-2">
                                {menu.title}
                              </span>
                              <span className="text-muted mt-2 fw-bold fs-5 ellipsis-1row">
                                {menu.subTitle}
                              </span>
                            </h3>
                            <figure className="ms-auto mb-0 mt-1">
                              <img
                                className="img-fluid"
                                src={menu.icon}
                                alt={menu.title}
                              />
                            </figure>
                          </div>
                        </div>
                      </Link>
                    )
                  );
                })}

                <div className="text-center mt-10">
                  <a
                    className="btn btn-link text-info text-hover-primary"
                    onClick={onHanleSignOut}
                    data-bs-dismiss="modal"
                  >
                    Sign out
                    <span className="svg-icon svg-icon-3  me-0 ms-2 opacity-50">
                      <SVG src="/images/icons/sign-out.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
