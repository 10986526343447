import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Question from './Components/Question';
import { get, find, last, findIndex, filter, groupBy } from 'lodash';
import {
  submitAnswer,
  fetchLatestAnswerByChapterId,
  fetchAssignedActivityById,
  addAnswerActivity,
  selectAnswerActivity,
  resetAnswerActivity,
  addAnsweredChapter,
  selectAnsweredChapter,
} from '../../../../../../features/client/activity';
import SVG from 'react-inlinesvg';
import Modal from './Components/Modal';
import ReactPlayer from 'react-player';
import { selectAuthUser } from '../../../../../../features/user/auth';
import { selectStateLoading } from '../../../../../../features/loading/loadingSlice';
import LinkBack from '../../../../../components/BackButton';
import ReactHtmlParser from 'react-html-parser';

const Chapters = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const isLoading = useSelector(selectStateLoading);

  const [activityDetail, setActivityDetail] = useState();
  const [listChapter, setListChapter] = useState([]);
  const [activeChapterId, setActiveChapterId] = useState();
  // latest answer by chapter
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [totalChapter, setTotalChapter] = useState(0);
  const [totalChapterAnswer, setTotalChapterAnswer] = useState(0);
  const [statusActivity, setStatusActivity] = useState();

  //modal
  const [wrongAnswerModalOpen, setWrongAnswerModalOpen] = useState(false);
  const [wooHooModalOpen, setWooHooModalOpen] = useState(false);
  const [doneModalOpen, setDoneModalOpen] = useState(false);

  // get answer save in redux
  const answerActivity = useSelector(selectAnswerActivity);
  const answeredChapter = useSelector(selectAnsweredChapter);

  useEffect(() => {
    setTotalChapterAnswer(answeredChapter.length);
  }, [answeredChapter]);

  const answerActivityByChapter = useMemo(() => {
    return filter(answerActivity, {
      chapterId: activeChapterId,
    });
  }, [activeChapterId, answerActivity]);

  const activeChapter = useMemo(() => {
    if (listChapter.length > 0) {
      return find(listChapter, { id: activeChapterId });
    }
  }, [listChapter, activeChapterId]);

  const lastChapterId = useMemo(() => {
    if (listChapter.length > 0) {
      return get(last(listChapter), 'id');
    }
  }, [listChapter]);

  useEffect(() => {
    return () => {
      dispatch(resetAnswerActivity());
    };
  }, []);

  useEffect(() => {
    const fetchAnswerByChapterId = async () => {
      const query = {
        chapter: activeChapterId,
      };
      const result = await dispatch(
        fetchLatestAnswerByChapterId(query)
      ).unwrap();

      setTotalChapter(result.total_chapters);
      const resultMap = result.latest_answer.map((item) => {
        if (item.question.answer_type === 'text') {
          return {
            questionId: item.question.id,
            text_answer: item.text_answer,
            type: 'text',
            isCorrect: true,
          };
        } else if (item.question.answer_type === 'multiple_choice') {
          return {
            questionId: item.question.id,
            answer: item.answer.id,
            type: 'multiple_choice',
            isCorrect: item.answer.is_correct,
          };
        }
      });
      // set latest answer by the value save in database or value save in redux
      if (resultMap.length > 0) {
        setQuestionAnswer(resultMap);
      } else {
        setQuestionAnswer(answerActivityByChapter);
      }
    };
    // if status is redo => not load latest answer and submit answer (let user do but not save data)
    if (activeChapterId && statusActivity !== 'Redo') {
      fetchAnswerByChapterId();
    } else {
      setQuestionAnswer(answerActivityByChapter);
    }
  }, [activeChapterId]);

  useEffect(() => {
    const fetchActivityDetail = async () => {
      const data = {
        clientId: authUser.id,
        id,
      };
      const result = await dispatch(fetchAssignedActivityById(data)).unwrap();
      setStatusActivity(get(result, 'status'));
      // if status is redo => not load latest answer and submit answer (let user do but not save data)
      if (get(result, 'status') === 'Redo') {
        setTotalChapter(get(result, 'total_chapters'));
        setTotalChapterAnswer(0);
      } else {
        setTotalChapterAnswer(get(result, 'total_answer_chapter'));
      }
      setActivityDetail(get(result, 'activity'));
      setListChapter(get(result, 'activity.chapters'));
      setActiveChapterId(get(result, 'activity.chapters[0].id'));
    };
    if (id && authUser) {
      fetchActivityDetail();
    }
  }, [id]);

  const mergeAnswer = (data) => {
    // merge answer
    const index = findIndex(questionAnswer, { questionId: data.questionId });
    if (index !== -1) {
      questionAnswer[index] = data;
    } else {
      questionAnswer.push(data);
    }
  };

  const submitAnswers = async (e, chapterId) => {
    e.preventDefault();
    const isAnswerAllMultipleQuestion = checkAnswerAllMultipleQuestion();
    if (!isAnswerAllMultipleQuestion) {
      return setWrongAnswerModalOpen(true);
    }
    // generate default answer = '' for text question if user not answer
    const listTextQuestion = filter(
      get(activeChapter, 'questions', []),
      function (o) {
        return o.answer_type === 'text';
      }
    );
    for (var i = 0; i < listTextQuestion.length; i++) {
      const index = findIndex(questionAnswer, {
        questionId: listTextQuestion[i].id,
      });
      if (index === -1) {
        questionAnswer.push({
          chapterId,
          questionId: listTextQuestion[i].id,
          text_answer: '',
          type: 'text',
          isCorrect: true,
        });
      }
    }
    // submit first answer
    if (statusActivity !== 'Redo') {
      const data = {
        chapter: chapterId,
        question_answer: questionAnswer,
        activity: +id,
      };
      const result = await dispatch(submitAnswer(data)).unwrap();
      setTotalChapterAnswer(result.total_answer_chapter);
      if (result) {
        const isValidate = validateAnswers();
        if (!isValidate) {
          return setWrongAnswerModalOpen(true);
        }

        if (activeChapterId === lastChapterId) {
          setWooHooModalOpen(true);
        } else {
          setDoneModalOpen(true);
        }
      }
      // if status is redo => not load latest answer and submit answer (let user do but not save data)
    } else {
      dispatch(addAnswerActivity(questionAnswer));
      dispatch(addAnsweredChapter({ chapterId }));
      const isValidate = validateAnswers();
      if (!isValidate) {
        return setWrongAnswerModalOpen(true);
      }

      if (activeChapterId === lastChapterId) {
        setWooHooModalOpen(true);
      } else {
        setDoneModalOpen(true);
      }
    }
  };

  const nextChapter = async () => {
    const chapterIds = listChapter.map((chapter) => chapter.id);
    const index = chapterIds.findIndex(
      (chapterId) => chapterId == activeChapterId
    );
    if (index > -1 && index < chapterIds.length - 1) {
      setActiveChapterId(chapterIds[+index + 1]);
    }
  };

  const checkAnswerAllMultipleQuestion = () => {
    let result = true;
    const listMultipleChoiceQuestion = filter(
      get(activeChapter, 'questions', []),
      function (o) {
        return o.answer_type === 'multiple_choice';
      }
    );
    const listMultipleChoiceAnswer = filter(questionAnswer, function (o) {
      return o.type === 'multiple_choice';
    });
    // check answers all multiple choice questions or not
    if (listMultipleChoiceQuestion.length != listMultipleChoiceAnswer.length) {
      result = false;
    }
    return result;
  };

  const validateAnswers = () => {
    let result = true;
    const listMultipleChoiceAnswer = filter(questionAnswer, function (o) {
      return o.type === 'multiple_choice';
    });
    // check answers all multiple choice questions or not
    for (var i = 0; i < listMultipleChoiceAnswer.length; i++) {
      if (!listMultipleChoiceAnswer[i].isCorrect) {
        result = false;
      }
    }
    return result;
  };

  const onNavigateChapter = (e, chapterId) => {
    e.preventDefault();
    dispatch(addAnswerActivity(questionAnswer));
    setActiveChapterId(chapterId);
  };

  const onScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    if (isLoading === 'loading') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, [isLoading]);

  return (
    <>
      <div
        className="bg-white shadow-sm d-lg-none py-2 px-4"
        data-kt-sticky="true"
        data-kt-sticky-name="docs-sticky-summary"
        data-kt-sticky-offset="{default: '200px'}"
        data-kt-sticky-width="100%"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="55px"
        data-kt-sticky-animation="true"
        data-kt-sticky-zindex={95}
        style={{ animationDuration: '0.3s' }}
        id="kt_back"
      >
        <div className="d-flex align-items-center overflow-auto">
          <div className="me-10 ms-n5">
            <LinkBack />
          </div>
          {listChapter &&
            listChapter.length > 0 &&
            listChapter.map((chapter, index) => {
              return (
                <div
                  key={chapter.id}
                  className="me-10 d-flex align-items-center text-nowrap"
                  onClick={(e) => onNavigateChapter(e, chapter.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="symbol symbol-30px me-2">
                    <span className="symbol-label bg-primary text-white">
                      {index + 1}
                    </span>
                  </div>
                  <h3 className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                    <p
                      className="text-dark fw-bolder text-hover-primary fs-7 mb-0"
                      style={{ cursor: 'pointer' }}
                    >
                      {index === 0
                        ? 'Intro'
                        : `Chapter ${index < 9 ? `0${index}` : index}`}
                    </p>
                  </h3>
                </div>
              );
            })}
        </div>
        {/* progress-bar */}
        <div className="mt-lg-0">
          <div className="d-flex flex-center wrap-progress">
            <div className="progress h-6px w-100 bg-secondary">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${(totalChapterAnswer * 100) / setTotalChapter}%`,
                }}
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <span className="text-muted fs-7 fw-bold ps-3 d-none d-lg-block">
              {`${((totalChapterAnswer * 100) / setTotalChapter).toFixed(2)}%`}
            </span>
          </div>
        </div>
        {/* end of progress-bar */}
      </div>
      <div className="d-flex flex-column flex-column-fluid position-relative">
        <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
          <div className="container px-40">
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 d-none d-lg-block">
                <div
                  className="card card-flush mb-0 bg-accent"
                  style={{
                    width: 'inherit',
                    left: 'inherit',
                  }}
                >
                  <div className="card-body">
                    <div
                      className="bgi-no-repeat bgi-size-cover rounded min-h-200px mw-350px mx-auto ribbon ribbon-start ribbon-clip"
                      style={{
                        backgroundImage: `url('${get(
                          activityDetail,
                          'activity_picture.url'
                        )}')`,
                        marginBottom: 15,
                      }}
                    >
                      <div className="ribbon-label ribbon-topic">
                        {get(activityDetail, 'code')}
                        <span className="ribbon-inner bg-warning"></span>
                      </div>
                    </div>

                    {/* progress-bar */}
                    <div className="mt-lg-0">
                      <div className="d-flex flex-center wrap-progress">
                        <div className="progress h-6px w-100 bg-secondary">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${
                                (totalChapterAnswer * 100) / setTotalChapter
                              }%`,
                            }}
                            aria-valuenow="40"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <span className="text-muted fs-7 fw-bold ps-3 d-none d-lg-block">
                          {`${(
                            (totalChapterAnswer * 100) /
                            setTotalChapter
                          ).toFixed(2)}%`}
                        </span>
                      </div>
                    </div>
                    {/* end of progress-bar */}

                    <div className="mt-4">
                      <div
                        className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row"
                        id="kt_stepper_example_vertical"
                      >
                        <div className="d-flex flex-row-auto w-100 w-lg-300px">
                          <div className="stepper-nav">
                            <div
                              className="stepper-item me-5 false"
                              data-kt-stepper-element="nav"
                            >
                              <div className="stepper-line"></div>
                              <div className="stepper-icon w-40px h-40px">
                                <i className="stepper-check fas fa-check"></i>
                                <span className="stepper-number">
                                  <span className="svg-icon svg-icon-3 svg-icon-white ">
                                    <SVG src="/images/icons/bookmark.svg" />
                                  </span>
                                </span>
                              </div>
                              <Link
                                className="stepper-label"
                                to={`/client/activity/${id}`}
                              >
                                <h3 className="stepper-title">Intro</h3>
                              </Link>
                            </div>
                            {listChapter.map((chapter, index) => (
                              <div
                                key={index}
                                className={`stepper-item me-5 ${
                                  chapter.id == activeChapterId ? 'current' : ''
                                }`}
                                data-kt-stepper-element="nav"
                              >
                                <div className="stepper-line"></div>
                                <div className="stepper-icon w-40px h-40px">
                                  <i className="stepper-check fas fa-check"></i>
                                  <span className="stepper-number">
                                    <span className="text-white">
                                      {index + 1}
                                    </span>
                                  </span>
                                </div>
                                <a
                                  className="stepper-label"
                                  href="#"
                                  onClick={(e) =>
                                    onNavigateChapter(e, chapter.id)
                                  }
                                >
                                  <h3 className="stepper-title">
                                    Chapter {index < 9 ? 0 : ''}
                                    {index + 1}
                                  </h3>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex-lg-row-fluid ms-lg-10"
                style={{ postition: 'absolute', top: -37 }}
              >
                <div className="my-4 mt-lg-0">
                  <div className="d-flex flex-center wrap-progress">
                    <div className="progress h-6px w-100 bg-secondary">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${
                            (totalChapterAnswer * 100) / totalChapter
                          }%`,
                        }}
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span className="text-muted fs-7 fw-bold ps-3 d-none d-lg-block">
                      {`${((totalChapterAnswer * 100) / totalChapter).toFixed(
                        2
                      )}%`}
                    </span>
                  </div>
                </div>
                {activeChapter && (
                  <>
                    {get(activeChapter, 'asset_type') === 'Text' && (
                      <div className="card mb-5 bg-secondary">
                        <div className="card-body">
                          <p>
                            {ReactHtmlParser(get(activeChapter, 'asset_url'))}
                          </p>
                        </div>
                      </div>
                    )}
                    {get(activeChapter, 'asset_type') === 'Video' && (
                      <div className="embed-responsive embed-responsive-16by9 mb-10 text-center">
                        <ReactPlayer
                          url={get(activeChapter, 'asset_url')}
                          className="w-100 h-300px h-md-400px embed-responsive-item"
                          controls={true}
                        />
                      </div>
                    )}
                    {get(activeChapter, 'asset_type') === 'Image' && (
                      <div className="embed-responsive embed-responsive-16by9 mb-10 text-center">
                        <img
                          className="w-100 h-300px h-md-400px embed-responsive-item"
                          style={{ objectFit: 'contain' }}
                          src={get(activeChapter, 'asset_url')}
                        />
                      </div>
                    )}
                    {get(activeChapter, 'questions', []).length > 0 && (
                      <div className="card mx-n5 mx-lg-0">
                        <div className="card-body p-5 p-md-8">
                          {get(activeChapter, 'questions', []).map(
                            (question, index) => (
                              <Question
                                key={`${get(activeChapter, 'id')} ${index}`}
                                question={question}
                                chapter={activeChapterId}
                                index={index}
                                answers={get(question, 'answers')}
                                onAnswered={(data) => mergeAnswer(data)}
                                answered={find(questionAnswer, function (item) {
                                  return (
                                    get(item, 'questionId') === question.id
                                  );
                                })}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="text-md-end">
                  <a
                    className="mt-12 btn btn-primary w-100 mw-md-200px"
                    href="#"
                    onClick={(e) => {
                      submitAnswers(e, activeChapterId);
                    }}
                  >
                    {activeChapterId === lastChapterId
                      ? 'Complete Activity'
                      : 'Continue'}
                    <span className="svg-icon svg-icon-4  ">
                      <SVG src="/images/icons/right.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        content="Not quite - Let's try again!"
        isOpen={wrongAnswerModalOpen}
        onClose={() => setWrongAnswerModalOpen(false)}
        onConfirm={(e) => {
          e.preventDefault();
          setWrongAnswerModalOpen(false);
        }}
      />

      <Modal
        content="All done"
        doneMessage={get(activityDetail, 'done_message')}
        style={{
          backgroundImage:
            "url('/images/illustrations/bg-modal-between-chapters.svg')",
          backgroundSize: '140%',
          backgroundPosition: '-40px -160px',
        }}
        isOpen={wooHooModalOpen}
        onClose={() => history.push('/client/activity')}
        onConfirm={(e) => {
          e.preventDefault();
          history.push('/client/activity');
        }}
      />

      <Modal
        content="Woohoo!"
        doneMessage={get(activeChapter, 'chapter_notes')}
        isOpen={doneModalOpen}
        onClose={() => {
          nextChapter();
          setDoneModalOpen(false);
        }}
        onConfirm={(e) => {
          e.preventDefault();
          nextChapter();
          setDoneModalOpen(false);
        }}
      />
    </>
  );
};

export default Chapters;
