import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';

import {
  previewAssignedActivity,
  selectPreviewActivity,
  resetPreviewActivity,
} from '../../../../../features/user';
import AssignedClientRow from './AssignedClientRow';
import PreviewActivity from './PreviewActivity';
import Pagination from '../../../../components/Pagination';

const AssignedClientList = ({
  assignedClients,
  onDeleteAssignedActivity,
  onPageClick,
  onChangeSize,
  pagination,
}) => {
  const dispatch = useDispatch();
  const [assignedActivityId, setAssignedActivityId] = useState('');
  const activityPreview = useSelector(selectPreviewActivity);
  const previewActivity = (assignedClient) => () => {
    dispatch(
      previewAssignedActivity({
        clientId: assignedClient.assigned_user.id,
        id: assignedClient.activity.id,
      })
    );
  };

  const getAssignedActivityId = (id) => () => {
    return setAssignedActivityId(id);
  };

  const handleClose = () => {
    return dispatch(resetPreviewActivity());
  };

  return (
    <>
      <div
        id="kt_datatable_example_2_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div className="mobile-table">
          <table
            className="table table-striped table-row-bordered gy-5 gs-7 w-100 align-middle"
            id="kt_datatable_example_2"
          >
            <thead>
              <tr className="fw-bold fs-6 text-gray-800">
                <th className="min-w-125px ">Client Name</th>
                <th className="min-w-125px ">Assigned by</th>
                <th className="min-w-125px ">Assigned at</th>
                <th className="min-w-125px ">Completed at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {assignedClients?.length > 0 &&
                assignedClients.map((assignedClient, index) => {
                  return (
                    <AssignedClientRow
                      assignedClient={assignedClient}
                      key={assignedClient.id}
                      activityId={assignedClient.activity.id}
                      previewActivity={previewActivity}
                      getAssignedActivityId={getAssignedActivityId}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        {assignedClients?.length > 0 && (
          <Pagination
            limit={pagination._limit}
            onChangeSize={onChangeSize}
            onPageClick={onPageClick}
            total={assignedClients?.length}
          />
        )}
      </div>
      {/* Modal Delete */}
      <div className="modal fade" id="modal-warning" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center">
                <div className="my-10">
                  <i className="bi bi-exclamation-circle text-danger fs-5x" />
                </div>
                <h3>Are you sure you want to do this?</h3>
                <p>This action can not be undone</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="w-100px btn btn-light"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="w-100px btn btn-light-primary"
                type="button"
                data-bs-dismiss="modal"
                onClick={() => onDeleteAssignedActivity(assignedActivityId)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={!!activityPreview?.activity?.id}
        onClose={handleClose}
        style={{
          position: 'absolute',
          top: '10%',
          overflow: 'scroll',
          height: '100%',
          display: 'block',
        }}
      >
        <div className="modal-dialog modal-lg">
          {!!activityPreview?.activity?.id ? (
            <PreviewActivity
              activityPreview={activityPreview.activity}
              handleClose={handleClose}
            />
          ) : (
            <div />
          )}
        </div>
      </Modal>
    </>
  );
};

export default AssignedClientList;
