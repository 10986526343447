import { useMemo, useState } from 'react';
import { get, isArray, join, isEqual } from 'lodash';

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  if (!items && item.length <= 0) return [];
  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let firstValue;
        let secondValue;
        if (isArray(sortConfig.key)) {
          firstValue = join(
            sortConfig.key.map((item) => get(a, `${item}`, '')),
            ' '
          );
          secondValue = join(
            sortConfig.key.map((item) => get(b, `${item}`, '')),
            ' '
          );
        } else {
          firstValue = get(a, `${sortConfig.key}`, '');
          secondValue = get(b, `${sortConfig.key}`, '');
        }

        if (typeof firstValue === 'string' && typeof secondValue === 'string') {
          firstValue = firstValue.toLowerCase();
          secondValue = secondValue.toLowerCase();
        }

        if (firstValue < secondValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (firstValue > secondValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      isEqual(sortConfig.key, key) &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
