import React from 'react';
import Modal from '@mui/material/Modal';

function ConfirmModal({ isOpenConfimModal, onCloseModal, handleDeleteTopic }) {
  return (
    <Modal open={isOpenConfimModal} onClose={onCloseModal}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <div className="my-10">
                <i className="bi bi-exclamation-circle text-danger fs-5x" />
              </div>
              <h3>Are you sure you want to do this?</h3>
              <p>This action can not be undone</p>
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-center">
            <button
              className="w-100px btn btn-light"
              type="button"
              data-bs-dismiss="modal"
              onClick={onCloseModal}
            >
              Cancel
            </button>
            <button
              className="w-100px btn btn-light-primary"
              type="button"
              onClick={handleDeleteTopic}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;
